import React, { useState, useEffect } from "react";

import {
  Layout,
  Button,
  Row,
  Col,
  Input,
  Card,
  Form,
  Spin,
  Select,
  Space,
  Upload,
  message,
  InputNumber,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { db } from "utils/firebase";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import moment from "moment-timezone";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";

import "./styles.less";
import { IS_FUNIX } from "utils/constants";
import { createDicts, dictList } from "apis/dict";

const { REACT_APP_TENANT_ID } = process.env;

const Dicts = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { user } = useAuth();
  const [form] = Form.useForm();
  const [dicts, setDicts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    if (user && loading) {
      setLoading(true);

      let query = db.collection("dicts");

      if (IS_FUNIX) query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
      else query = query.where("userId", "==", user?.uid);
      query.get().then(async (data) => {
        if (data.empty) {
          setLoading(false);
          return;
        }

        let dicts = await Promise.all(
          data.docs.map((doc) => {
            let item = doc.data();
            return {
              id: doc.id,
              ...item,
            };
          })
        );

        setDicts((prev) => [...prev, ...dicts]);
        setLoading(false);
      });
    }
  }, [user]);

  const onSave = async (dicts, successMessage) => {
    setLoadingSave(true);

    try {
      //get
      let query = db.collection("dicts");

      if (IS_FUNIX) query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
      else query = query.where("userId", "==", user?.uid);

      let dictsDoc = await query.get();

      await Promise.all(
        dictsDoc.docs.map((doc) => {
          return db.collection("dicts").doc(doc.id).delete();
        })
      );
      //add new
      await Promise.all(
        dicts.map((item, index) => {
          return db.collection("dicts").add({
            userId: user?.uid,
            word1: item?.word1 || "",
            word3: item?.word3 || "",
            word5: item?.word5 || "",
            sortBy: item?.sortBy || 10,
            tenantId: REACT_APP_TENANT_ID,
          });
        })
      );

      setDicts(dicts);
      form.setFieldsValue({ dicts });
      message.success(successMessage || lang.dictionarySuccess);
    } catch (e) {
      message.error(e.message ?? lang.saveFailed);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Layout className="jobs-page">
      <Layout
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          marginTop: 20,
          marginBottom: 24,
        }}
      >
        {loading ? (
          <Spin />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="info"
            onFinish={async (values) => {
              onSave(values?.dicts);
            }}
            initialValues={{
              dicts: dicts,
            }}
          >
            <Card style={{ marginBottom: 18 }}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                justify="space-between"
              >
                <Col className="gutter-row" span={12}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h3>{lang.dictionary}</h3>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  span={12}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    size="large"
                    loading={loadingSave}
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                  >
                    {lang.btnSave}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="gutter-row" span={24}>
                  <Space>
                    <Button
                      type={"link"}
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        //generate data
                        const templateData = dicts.map((item) => {
                          return {
                            word1: item?.word1,
                            word3: item?.word3,
                            word5: item?.word5,
                            sortBy: item?.sortBy,
                          };
                        });

                        const fileType =
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                        const fileExtension = ".xlsx";
                        const ws = XLSX.utils.json_to_sheet(templateData);
                        const wb = {
                          Sheets: { Data: ws },
                          SheetNames: ["Data"],
                        };

                        // //Add styles
                        // var sheetData = wb.SheetNames[0];
                        // var worksheet = wb.Sheets[sheetData];

                        // //bgColor
                        // for (let i = 1; i <= sortedDicts.length + 1; i++) {
                        //   const cellAddress = `F${i}`; //F: SL Giao
                        //   const cell = worksheet[cellAddress];
                        //   cell.s = {
                        //     fill: {
                        //       fgColor: { rgb: 'DDEBF7' },
                        //     },
                        //   };
                        // }

                        // const cols = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
                        // cols.forEach(col => {
                        //   //border, bold (header)
                        //   for (let i = 1; i <= sortedDicts.length + 1; i++) {
                        //     const cellAddress = `${col}${i}`; //F: SL Giao
                        //     const cell = worksheet[cellAddress];

                        //     cell.s = {
                        //       font: (i === 1 || col === 'F' || col === 'B' || col === 'G') ? {
                        //         bold: true,
                        //       } : {
                        //         bold: false,
                        //       },
                        //       fill: col === 'F' || col === 'B' || col === 'G' ? {
                        //         fgColor: { rgb: 'DDEBF7' },
                        //       } : {
                        //         patternType: 'none',
                        //       },
                        //       border: {
                        //         top: {
                        //           style: 'dashed',
                        //           color: 'FFFFAA00',
                        //         },
                        //         bottom: {
                        //           style: 'dashed',
                        //           color: 'FFFFAA00',
                        //         },
                        //         left: {
                        //           style: 'dashed',
                        //           color: 'FFFFAA00',
                        //         },
                        //         right: {
                        //           style: 'dashed',
                        //           color: 'FFFFAA00',
                        //         },
                        //       },
                        //     };
                        //   }
                        // });

                        const excelBuffer = XLSX.write(wb, {
                          bookType: "xlsx",
                          type: "array",
                        });
                        const data = new Blob([excelBuffer], {
                          type: fileType,
                        });
                        const fileName = `dictionary_${moment()
                          .format("MMMM_Do_YYYY_h_mm")
                          .trim()}`;
                        FileSaver.saveAs(data, fileName + fileExtension);
                      }}
                    >
                      {lang.btnDownload}
                    </Button>
                    <Upload
                      accept=".xlsx"
                      style={{ marginLeft: 100 }}
                      beforeUpload={(file) => {
                        //Read data
                        setLoading(true);
                        const reader = new FileReader();
                        reader.onload = async (evt) => {
                          // evt = on_file_select event
                          /* Parse data */
                          const bstr = evt.target.result;
                          const wb = XLSX.read(bstr, { type: "binary" });
                          /* Get first worksheet */
                          const wsname = wb.SheetNames[0];
                          const ws = wb.Sheets[wsname];
                          /* Convert array of arrays */
                          const data = XLSX.utils.sheet_to_json(ws, {
                            header: 1,
                          });

                          //data[0] is header
                          const itemsUpdate = [];
                          const itemsError = [];
                          for (let i = 1; i < data.length; i++) {
                            //Template:
                            //[0]: word1
                            //[1]: word2
                            //[2]: word3
                            //[3]: word4
                            //[4]: word5

                            if (
                              !isNaN(data[i][3]) &&
                              data[i][0] !== undefined &&
                              (data[i][1] !== undefined) &
                                (data[i][2] !== undefined) &&
                              (data[i][2] === "PERSON" ||
                                data[i][2] === "LOCATION" ||
                                data[i][2] === "ORGANIZATION" ||
                                data[i][2] === "OTHER")
                            ) {
                              let row = {
                                word1: data[i][0],
                                word3: data[i][1],
                                word5: data[i][2],
                                sortBy: data[i][3],
                              };
                              itemsUpdate.push(row);
                            } else {
                              itemsError.push(i);
                            }
                          }

                          if (data.length - 1 - itemsUpdate.length !== 0) {
                            message.error(
                              `${data.length - 1 - itemsUpdate.length} ${
                                lang.line
                              } (${itemsError.toString()}) ${lang.uploadFail}`
                            );
                          } else {
                            await onSave(
                              itemsUpdate,
                              `${itemsUpdate.length} ${lang.uploadSuccess}`
                            );
                          }
                          setLoading(false);
                        };
                        reader.readAsBinaryString(file);
                        return false;
                      }}
                      onRemove={(file) => {
                        //setDicts(dicts);
                      }}
                      maxCount={1}
                    >
                      <Button
                        loading={loading}
                        type={"primary"}
                        icon={<UploadOutlined />}
                      >
                        {lang.btnUpload}
                      </Button>
                    </Upload>
                  </Space>
                </Col>
              </Row>
            </Card>
            {loading ? (
              <Spin />
            ) : (
              <Form.List name="dicts">
                {(fields, { add, remove }) => (
                  <>
                    <Row style={{ marginBottom: 10 }}>
                      <Col span={24}>
                        <span style={{ fontWeight: "bold" }}>
                          {lang.totalWords}: {dicts.length}
                        </span>
                      </Col>
                    </Row>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Row gutter={24}>
                          <Col lg={{ span: 1 }} xs={{ span: 1 }}>
                            <div>{key + 1}.</div>
                          </Col>
                          <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                            <Form.Item
                              label={lang.word1}
                              {...restField}
                              name={[name, "word1"]}
                              rules={[
                                { required: true, message: lang.word1Required },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                            <Form.Item
                              label={lang.word3}
                              {...restField}
                              name={[name, "word3"]}
                              rules={[
                                { required: true, message: lang.word3Required },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                            <Form.Item
                              label={lang.word5}
                              {...restField}
                              name={[name, "word5"]}
                              rules={[
                                { required: true, message: lang.word5Required },
                              ]}
                            >
                              <Select placeholder="">
                                <Select.Option value={"PERSON"}>
                                  PERSON
                                </Select.Option>
                                <Select.Option value={"LOCATION"}>
                                  LOCATION
                                </Select.Option>
                                <Select.Option value={"ORGANIZATION"}>
                                  ORGANIZATION
                                </Select.Option>
                                <Select.Option value={"OTHER"}>
                                  OTHER
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 2 }} xs={{ span: 2 }}>
                            <Form.Item
                              label={lang.sortBy}
                              {...restField}
                              name={[name, "sortBy"]}
                              rules={[
                                {
                                  required: true,
                                  message: lang.sortByRequired,
                                },
                              ]}
                            >
                              <InputNumber min={0} />
                            </Form.Item>
                          </Col>
                          <Col
                            lg={{ span: 4 }}
                            xs={{ span: 4 }}
                            style={{ marginTop: 34 }}
                          >
                            <>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />{" "}
                              {lang.btnDelete}
                            </>
                          </Col>
                        </Row>
                      </>
                    ))}
                    <Button
                      style={{ marginLeft: -18 }}
                      type="link"
                      onClick={() =>
                        add({
                          word5: "OTHER",
                          sortBy: 10,
                        })
                      }
                      icon={<PlusOutlined />}
                    >
                      {lang.btnAdd}
                    </Button>
                  </>
                )}
              </Form.List>
            )}
          </Form>
        )}
      </Layout>
    </Layout>
  );
};

export default Dicts;
