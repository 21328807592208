import React, { useEffect, useCallback, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//1
import moment from "moment-timezone";
import "moment/locale/vi";
import "moment/locale/ja";
import detectBrowserLanguage from "detect-browser-language";
import { useTranslation } from "react-i18next";

import { useRecoilState } from "recoil";
import { adminAtom, localeAtom, userAtom } from "recoils/atoms";

import AdminLogin from "pages/Home/AdminLogin";
import UserLogin from "pages/Home/UserLogin";
import UserForgotPassword from "pages/Home/UserForgotPassword";
import UserStreamingTranscript from "pages/User/StreamingTranscript";
import UserMeetings from "pages/User/Meetings";

//Private - Admin
import AdminLayout from "components/web/Layout/Admin";
import Users from "pages/Admin/Users";
import AdminDicts from "pages/Admin/Dicts";
import AdminSaaSTranscripts from "pages/Admin/SaaSTranscripts";

import UserLayout from "components/web/Layout/User";
import UserRecordTranscript from "pages/User/RecordTranscript";

import UserDicts from "pages/User/Dicts";
import UserStatistics from "pages/User/Statistics";
import SaaSTranscripts from "pages/User/SaaSTranscripts";
import FUNiXSearch from "pages/User/FUNiXSearch";
import FUNiXUpload from "pages/User/FUNiXUpload";
import FUNiXFollowUp from "pages/User/FUNiXFollowUp";
import Monitor from "pages/User/Monitor";

import { ConfigProvider } from "antd";
import { IS_FUNIX } from "utils/constants";
import UserResetPassword from "pages/Home/UserResetPassword";
import MeetingTasks from "pages/User/MeetingTasks";
import MeetingDetail from "pages/User/MeetingDetail";
import { auth, db } from "utils/firebase";
import { message } from "antd";
import _ from "lodash";
import { AuthContext } from "hooks/useAuth";

const { REACT_APP_TENANT_ID, REACT_APP_SITENAME } = process.env;

const App = () => {
  const [locale, setLocale] = useRecoilState(localeAtom);
  const { i18n } = useTranslation();

  const setLanguageHandler = useCallback((languageDetectByBrowser) => setLocale(languageDetectByBrowser), [locale]);

  useEffect(() => {
    const languageDetectByBrowser = detectBrowserLanguage();
    if (!languageDetectByBrowser) {
      setLanguageHandler(languageDetectByBrowser);
    }
  }, [setLanguageHandler]);

  useEffect(() => {
    if (!locale || i18n.language === locale) return;
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }, [locale, i18n]);

  const isAdminRoute = window.location.pathname.split("/")[1].startsWith("admin");
  const [user, setUser] = useRecoilState(isAdminRoute ? adminAtom : userAtom);
  const [token, setToken] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // User Login
      if (!isAdminRoute && user && user.tenantId === REACT_APP_TENANT_ID) {
        user.getIdToken(true).then(async (token) => {
          //get data
          await db
            .doc(`users/${user.uid}`)
            .get()
            .then((doc) => {
              if (!doc.exists) {
                auth.signOut();
                return;
              }
              const userData = doc.data();
              if (!_.isUndefined(userData.isActive) && !userData.isActive) {
                message.error("Account deactivated");
                auth.signOut();
                return;
              }
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
                profile: doc.data(),
                tenantId: user?.tenantId,
              });
              setToken(token);
              setLoadingAuth(false);
            })
            .catch((err) => {
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
              });
              setToken(token);
              setLoadingAuth(false);
            });
        });
      }
      // Admin Login
      else if (isAdminRoute && user && !user.tenantId) {
        db.doc(`tenants/${user.uid}`)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return;
            } else {
              const tenantId = doc.data()?.tenantId;
              if (tenantId !== REACT_APP_TENANT_ID) {
                auth.signOut();
                setLoadingAuth(false);
                window.location.replace("/admin-login");
              }
              setUser({
                uid: user?.uid,
                email: user?.providerData[0]?.uid,
                tenantId: doc?.data()?.tenantId,
              });
              setLoadingAuth(false);
            }
          });
      } else {
        setLoadingAuth(false);
        setUser(null);
        if (window.location.pathname.split("/")[1] === "admin") window.location.replace("/admin-login");
        else if (window.location.pathname.split("/")[1] === "user") window.location.replace("/");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, loadingAuth, setLoadingAuth }}>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          <HelmetProvider>
            <Helmet>
              <title>{REACT_APP_SITENAME}</title>
            </Helmet>
            <Routes>
              <Route path="/" element={<UserLogin />} />
              <Route path="/forgot-password" element={<UserForgotPassword />} />
              <Route path="/reset/:token" element={<UserResetPassword />} />

              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="admin" element={<AdminLayout />}>
                <Route path="/" element={<Users />} />
                <Route path="users" element={<Users />} />
                <Route path="dicts" element={<AdminDicts />} />
                <Route path="transcripts" element={<AdminSaaSTranscripts />} />
              </Route>
              <Route path="user" element={<UserLayout />}>
                <Route path="record" element={<UserRecordTranscript />} />
                <Route path="streaming" element={<UserStreamingTranscript />} />
                <Route path="statistics" element={<UserStatistics />} />
                <Route path="transcripts">
                  <Route path="/" element={IS_FUNIX ? <FUNiXSearch /> : <SaaSTranscripts />} />
                  <Route path="followup" element={<FUNiXFollowUp />} />
                </Route>
                <Route path="transcripts/:fileId" element={<MeetingDetail />} />
                <Route path="dicts" element={<UserDicts />} />
                <Route path="meeting" element={<UserMeetings />}>
                  <Route path="/" element={<UserMeetings />}></Route>
                </Route>
                <Route path="meeting-tasks" element={<MeetingTasks />} />
                {IS_FUNIX && <Route path="upload" element={<FUNiXUpload />} />}
                {IS_FUNIX && <Route path="monitor" element={<Monitor />} />}
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </HelmetProvider>
        </BrowserRouter>
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export default App;
