import React, { useState, useEffect } from "react";

import { Card, Table, Layout, Button, Col, Modal } from "antd";

import "./styles.less";

import { db } from "utils/firebase";
import useAuth from "hooks/useAuth";
import MeetingTaskCreateForm from "components/web/User/MeetingTaskCreateForm";
import moment from "moment-timezone";

const MeetingTasks = () => {
  const { user } = useAuth();

  const [meetingTasks, setMeetingTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isCreateModal, setIsCreateModal] = useState(false);

  useEffect(() => {
    const start = async () => {
      db.collection("meetingTasks")
        .where("tenantId", "==", process.env.REACT_APP_TENANT_ID)
        .where("userId", "==", user.uid)
        .onSnapshot(async (querySnapshot) => {
          const meetingTasks = [];
          querySnapshot.forEach((doc) => {
            meetingTasks.push(doc.data());
          });
          setMeetingTasks(meetingTasks);
        });
    };
    start().finally(() => {
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "Room number",
      align: "center",
      width: "8%",
      defaultSortOrder: "descend",
      render: (record) => record.roomNumber,
    },
    {
      title: "Status",
      align: "center",
      width: "8%",
      defaultSortOrder: "descend",
      render: (record) => record.status,
    },
    {
      title: "Language",
      align: "center",
      width: "8%",
      defaultSortOrder: "descend",
      render: (record) => record.language,
    },
    {
      title: "Created At",
      align: "center",
      width: "8%",
      defaultSortOrder: "descend",
      render: (record) =>
        record.createdAt
          ? moment(record.createdAt.toDate()).format("YYYY-MM-DD HH:mm")
          : "",
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          marginTop: 20,
          marginBottom: 24,
        }}
      >
        <Card style={{ marginBottom: 18 }} bordered={false}>
          <Col span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ margin: 0 }}>Meeting Tasks</h2>
              <Button onClick={() => setIsCreateModal(true)}>
                Create new task
              </Button>
              <Modal
                visible={isCreateModal}
                title="User create"
                onCancel={() => setIsCreateModal(false)}
                destroyOnClose={true}
                width={570}
                centered
                footer={null}
              >
                <MeetingTaskCreateForm
                  onCancel={() => setIsCreateModal(false)}
                  onCallback={() => setIsCreateModal(false)}
                />
              </Modal>
            </div>
          </Col>
        </Card>
        <Card>
          <Table
            loading={loading}
            bordered
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={meetingTasks}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
            }}
          />
        </Card>
      </Layout>
    </Layout>
  );
};

export default MeetingTasks;
