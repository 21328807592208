/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import {
  Layout,
  Tabs,
  Table,
  Row,
  Col,
  Card,
  Upload,
  Input,
  Button,
  Form,
  Tag,
  Modal,
  Dropdown,
  Menu,
  Space,
  message,
  Select,
  DatePicker,
  Checkbox,
  InputNumber,
} from "antd";

import moment from "moment-timezone";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import "./styles.less";

import { monitorGet } from "apis/transcript";

import _extends from "@babel/runtime/helpers/extends";
import CalendarLocale from "rc-picker/es/locale/vi_VN";
import TimePickerLocale from "antd/es/time-picker/locale/vi_VN";

import usLocale from "antd/lib/date-picker/locale/en_US";

import * as firebase from "firebase";
import { db } from "utils/firebase";

const config = {
  apiKey: "AIzaSyBGvSvdeMxda3Z4tyLHpWgDtlWQhirUA4c",
  authDomain: "namisense-funix.firebaseapp.com",
  projectId: "namisense-funix",
  storageBucket: "namisense-funix.appspot.com",
  messagingSenderId: "126180177380",
  appId: "1:126180177380:web:8c159dd587ae6467b243f1",
};

const app = firebase.initializeApp(config, "FUNiXMonitor");

const storage = app.storage();
const auth = app.auth();

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const Users = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguages(t);

  const { user, token } = useAuth();

  const [files, setFiles] = useState([]);
  const [filterFiles, setFilterFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [statusListChecked, setStatusListChecked] = useState([]);

  //
  const [date, setDate] = useState(moment());
  const [storages, setStorages] = useState([]);
  const [filterStorages, setFilterStorages] = useState([]);

  const locale = {
    lang: _extends(
      {
        placeholder: "Chọn thời điểm",
        rangePlaceholder: ["Từ ngày", "Đến ngày"],
      },
      CalendarLocale
    ),
    timePickerLocale: _extends({}, TimePickerLocale),
  };

  useEffect(() => {
    auth
      .signInWithEmailAndPassword("funix@yopmail.com", "123.vietnam")
      .then(async () => {
        console.log("Funix login success");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(`${errorCode} - ${errorMessage}`);
      });
  }, []);

  useEffect(() => {
    const func = async () => {
      if (user) {
        setLoading(true);
        monitorGet({
          fromDate: startDate.format("YYYY-MM-DD"),
          toDate: endDate.format("YYYY-MM-DD"),
        })
          .then((res) => {
            setFiles(res.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    };

    func();
  }, [user]);

  useEffect(() => {
    if (files) {
      if (statusListChecked.length > 0) {
        setFilterFiles(
          files.filter((file) => statusListChecked.includes(file.status))
        );
      } else {
        setFilterFiles(files);
      }
    }
  }, [statusListChecked, files]);

  useEffect(() => {
    if (storages) {
      if (statusListChecked.length > 0) {
        setFilterStorages(
          storages.filter((storages) =>
            statusListChecked.includes(storages.status)
          )
        );
      } else {
        setFilterStorages(storages);
      }
    }
  }, [statusListChecked, storages]);

  const onSearch = async () => {
    setLoading(true);
    monitorGet({
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    })
      .then((res) => {
        setFiles(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: language.name,
      width: "10%",
      render: (text, record, index) => {
        if (record) {
          return <div>{record.name}</div>;
        } else return null;
      },
    },
    {
      title: language.createdAt,
      width: "10%",
      render: (text, record, index) => {
        if (record) {
          return <div>{moment(record.createdAt).format("DD-MM-YYYY")}</div>;
        } else return null;
      },
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => {
          return a.status.localeCompare(b.status);
        },
      },
      render: (record) => {
        switch (record.status) {
          case "noAudio":
            return <Tag color="#ff0000">{language.noAudioFound}</Tag>;
          case "noMeta":
            return <Tag color="#ff9300">{language.noMetadataFound}</Tag>;
          default:
            return null;
        }
      },
    },
  ];

  const columnsStorages = [
    {
      title: language.name,
      width: "10%",
      render: (text, record, index) => {
        if (record) {
          return <div>{record.path}</div>;
        } else return null;
      },
    },
    {
      title: language.createdAt,
      width: "10%",
      render: (text, record, index) => {
        if (record) {
          return <div>{record.date}</div>;
        } else return null;
      },
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      render: (record) => {
        switch (record.status) {
          case 0:
            return (
              <Tag color="#ff0000">{"Không tìm thấy trong Task Queue"}</Tag>
            );
          case 1:
            return <Tag color="#ff9300">{"Đã tìm thấy"}</Tag>;
          default:
            return null;
        }
      },
    },
  ];

  return (
    <Layout className="jobs-page">
      <Tabs
        type="card"
        size="medium"
        tabBarStyle={{ margin: "25px 24px 0px 24px" }}
      >
        <TabPane tab={"MetaData.xlsx"} key="record">
          <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
            <Card style={{ marginBottom: 20 }}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                justify="space-between"
              >
                <Col className="gutter-row" span={12}>
                  <h3>{language.monitor}</h3>
                </Col>
              </Row>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <RangePicker
                    style={{ marginRight: 10, marginBottom: 18, width: 410 }}
                    onChange={(date, dateString) => {
                      if (date) {
                        setStartDate(date[0]);
                        setEndDate(date[1]);
                      } else {
                        setStartDate(null);
                        setEndDate(null);
                      }
                    }}
                    value={[startDate, endDate]}
                    format={"DD-MM-YYYY"}
                    ranges={{
                      [language.today]: [moment(), moment()],
                      [language.yesterday]: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      [language.thisWeek]: [
                        moment().startOf("week"),
                        moment().endOf("week"),
                      ],
                      [language.thisMonth]: [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      [language.thisQuarter]: [
                        moment().startOf("quarter"),
                        moment().endOf("quarter"),
                      ],
                      [language.thisYear]: [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                    }}
                    locale={i18n.language === "en" ? usLocale : locale}
                    allowClear={false}
                  />

                  <Button
                    type="primary"
                    style={{ width: 125, marginBottom: 18 }}
                    onClick={() => onSearch()}
                    loading={loading}
                  >
                    {language.search}
                  </Button>

                  <Button
                    type="primary"
                    style={{ position: "absolute", right: 30 }}
                    loading={loading}
                    onClick={() => {
                      const data = files.map((item) => {
                        return [
                          item.name,
                          moment(item.createdAt).format("DD-MM-YYYY"),
                          item.status === "noAudio"
                            ? language.noAudioFound
                            : language.noMetadataFound,
                        ];
                      });

                      let encodedUri =
                        "data:text/csv;charset=utf-8,%EF%BB%BF" +
                        encodeURI(
                          data
                            .filter((l) => l)
                            .map((e) => e.map((item) => `"${item}"`).join(","))
                            .join("\n")
                        );
                      var link = document.createElement("a");
                      link.setAttribute("href", encodedUri);
                      link.setAttribute(
                        "download",
                        `${language.monitor}_${moment().format(
                          "DD-MM-YYYY"
                        )}.csv`
                      );
                      document.body.appendChild(link); // Required for FF
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    {language.export}
                  </Button>
                </div>
              </div>
            </Card>

            <Row gutter={16}>
              <Col span={5}>
                <Card style={{ height: "100%" }} loading={loading}>
                  <div style={{ color: "#4a90e2" }}>
                    <span style={{ fontWeight: "bold" }}>{files.length}</span>{" "}
                    {language.searchResults}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "600", marginTop: 20 }}>
                        {language.status}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                      }}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setStatusListChecked(e);
                        }}
                        value={statusListChecked}
                      >
                        <Row>
                          <Col span={24}>
                            <Checkbox value="noAudio">
                              {language.noAudioFound}{" "}
                              <b>
                                (
                                {
                                  files.filter(
                                    (item) => item.status === "noAudio"
                                  ).length
                                }
                                )
                              </b>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="noMeta">
                              {language.noMetadataFound}{" "}
                              <b>
                                (
                                {
                                  files.filter(
                                    (item) => item.status === "noMeta"
                                  ).length
                                }
                                )
                              </b>
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={19}>
                <Card>
                  <p>
                    <b>{filterFiles.length}</b> {language.filterResults} /{" "}
                    <span style={{ color: "#4a90e2" }}>
                      <b>{files.length}</b> {language.searchResults}
                    </span>
                  </p>
                  <Table
                    loading={loading}
                    bordered
                    columns={columns}
                    dataSource={filterFiles}
                    pagination={{
                      defaultPageSize: 20,
                      showSizeChanger: true,
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Layout>
        </TabPane>
        <TabPane tab={"Task Queue & Stogare"} key="tasks">
          <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
            <Card style={{ marginBottom: 20 }}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                justify="space-between"
              >
                <Col className="gutter-row" span={12}>
                  <h3>{language.monitor}</h3>
                </Col>
              </Row>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <DatePicker
                    value={date}
                    onChange={(date, dateString) => {
                      setLoadingTask(true);
                      if (date.isAfter(moment(), "day")) {
                        setDate(moment());
                        message.error(language.uploadDateFuture);
                      } else {
                        setDate(date);
                      }

                      setStorages([]);
                      const storageRef = storage.ref(
                        `${date.format("DDMMYYYY")}`
                      );

                      // Find all the prefixes and items.
                      storageRef
                        .listAll()
                        .then(async (res) => {
                          let results = await Promise.all(
                            res.items.map(async (itemRef) => {
                              let audioTasks = await db
                                .collection("audioTasks")
                                .where(
                                  "fileName",
                                  "==",
                                  itemRef?.location?.path_.substring(9)
                                )
                                .get();
                              return {
                                date: date.format("DDMMYYYY"),
                                path: itemRef?.location?.path_.substring(9),
                                status: audioTasks.docs.length > 0 ? 1 : 0,
                              };
                            })
                          );

                          let resultsMetaData = await Promise.all(
                            results.map(async (item) => {
                              if (item.status === 1) {
                                return item;
                              } else {
                                let metadataTasks = await db
                                  .collection("metadataTasks")
                                  .where("fileName", "==", item?.path)
                                  .get();
                                return {
                                  ...item,
                                  status:
                                    metadataTasks.docs.length > 0
                                      ? 1
                                      : item?.status,
                                };
                              }
                            })
                          );

                          setStorages(resultsMetaData);
                          setFilterStorages(resultsMetaData);
                          setLoadingTask(false);
                        })
                        .catch((error) => {
                          // Uh-oh, an error occurred!
                        });
                    }}
                    format="DD-MM-YYYY"
                    allowClear={false}
                    locale={i18n.language === "en" ? usLocale : locale}
                  />
                </div>
              </div>
            </Card>
            <Row gutter={16}>
              <Col span={5}>
                <Card style={{ height: "100%" }} loading={loadingTask}>
                  <div style={{ color: "#4a90e2" }}>
                    <span style={{ fontWeight: "bold" }}>
                      {storages.length}
                    </span>{" "}
                    {language.searchResults}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "600", marginTop: 20 }}>
                        {language.status}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                      }}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setStatusListChecked(e);
                        }}
                        value={statusListChecked}
                      >
                        <Row>
                          <Col span={24}>
                            <Checkbox value={0}>
                              Không tìm thấy Task queue{" "}
                              <b>
                                (
                                {
                                  storages.filter((item) => item.status === 0)
                                    .length
                                }
                                )
                              </b>
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value={1}>
                              Đã tìm thấy{" "}
                              <b>
                                (
                                {
                                  storages.filter((item) => item.status === 1)
                                    .length
                                }
                                )
                              </b>
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={19}>
                <Card>
                  <p>
                    <b>{filterStorages.length}</b> {language.filterResults} /{" "}
                    <span style={{ color: "#4a90e2" }}>
                      <b>{storages.length}</b> {language.searchResults}
                    </span>
                  </p>
                  <Table
                    loading={loadingTask}
                    bordered
                    columns={columnsStorages}
                    dataSource={filterStorages}
                    pagination={{
                      defaultPageSize: 20,
                      showSizeChanger: true,
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Layout>
        </TabPane>
      </Tabs>
    </Layout>
  );
};

export default Users;
