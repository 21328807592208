import { CaretRightOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Typography } from "antd";
import useDataToolRole from "hooks/useDataToolRole";
import { cloneDeep, orderBy, values } from "lodash";
import { useSlateStatic } from "slate-react";
import { formatTimeToString, speakerToString } from "utils/utils";
import { changeTime } from "./util";
import React from "react";

const TranscriptTile = ({
  sentence,
  videoRef,
  playTo,
  pauseAtEnd,
  speakers,
  setSpeakers,
  readOnly,
  value,
}) => {
  const speakerString = speakerToString(sentence.speaker);
  const editor = useSlateStatic();
  const dataToolRole = useDataToolRole();

  const handleChangeSpeaker = (all, speaker) => {
    editor.apply({
      type: "change_speaker",
      sentence,
      speaker,
      all,
    });

    setSpeakers((speakers) => {
      const clonedSpeakers = cloneDeep(speakers);
      clonedSpeakers[speaker].unused = false;

      if (
        value.current.find(
          ({ sentence: currentSentence }) =>
            speakerToString(currentSentence.speaker) === speakerString &&
            sentence !== currentSentence
        )
      ) {
        clonedSpeakers[speakerString].unused = false;
      } else {
        clonedSpeakers[speakerString].unused = true;
      }

      return clonedSpeakers;
    });
  };

  const menu = speakers ? (
    <Menu style={{ maxHeight: 240, overflowY: "auto" }}>
      {values(orderBy(speakers, "label")).map((speaker) => (
        <Menu.Item
          key={speaker.label}
          onClick={() => handleChangeSpeaker(false, speaker.label)}
        >
          <Space>
            {speakerString === speaker.label ? (
              <CaretRightOutlined />
            ) : (
              <div style={{ width: 14 }} />
            )}
            {speaker.label}
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  ) : null;

  return (
    <div
      className="transcriptEditor-title"
      style={{ userSelect: "none", WebkitUserSelect: "none" }}
      contentEditable={false}
    >
      {readOnly ? (
        <span
          className="transcriptEditor-title-speaker"
          style={{ cursor: "default" }}
        >
          {speakerString}
        </span>
      ) : (
        <Dropdown trigger={["click"]} overlay={menu}>
          <span className="transcriptEditor-title-speaker">
            {speakerString}
          </span>
        </Dropdown>
      )}
      <Typography.Text
        className="transcriptEditor-title-time"
        type="secondary"
        onClick={(e) => {
          changeTime(videoRef.current, sentence.start);
          if (pauseAtEnd.current) {
            playTo.current = sentence.end;
          } else {
            playTo.current = null;
          }
        }}
      >
        <CaretRightOutlined />
        {formatTimeToString(sentence.start)}
        {dataToolRole && <> - {formatTimeToString(sentence.end)}</>}
      </Typography.Text>
    </div>
  );
};

export default TranscriptTile;
