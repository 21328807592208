import React, { useState, useEffect } from "react";

import { Layout, Table, Button, Tag, Card, Row, Col } from "antd";
import { SyncOutlined, EyeOutlined } from "@ant-design/icons";

import moment from "moment-timezone";
import { db } from "utils/firebase";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import "./styles.less";
import { useNavigate } from "react-router";

const Users = () => {
  const { t } = useTranslation();
  const language = getLanguages(t);

  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    db.collection("files")
      .where("tenantId", "==", process.env.REACT_APP_TENANT_ID)
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const files = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data?.createdAt?.toDate(),
            length: convertToDuration(data?.length),
          };
        });

        setFiles(files);
      });

    db.collection("users")
      .where("tenantId", "==", process.env.REACT_APP_TENANT_ID)
      .onSnapshot((snapshot) => {
        const users = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          };
        });

        setUsers(users);
      });
  }, []);

  const convertToDuration = (value) => {
    if (isNaN(value) || !isFinite(value)) return "00:00:00";

    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const columns = [
    {
      title: language.user,
      width: "3%",
      dataIndex: "user",
      render: (text, record, index) => {
        return (
          <div>
            <div>{users?.find((item) => item?.id === record?.user)?.name}</div>
          </div>
        );
      },
      filterMultiple: true,
      filters: users?.map((item) => {
        return {
          text: item.name,
          value: item?.id,
        };
      }),
      onFilter: (value, record) => record.user === value,
    },
    {
      title: language.name,
      dataIndex: "name",
      width: "15%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/transcripts/edit/${record.id}`);
              }}
            >
              {record.name}
            </a>
          );
        } else {
          return <div>{record.name}</div>;
        }
      },
    },
    {
      title: language.length,
      align: "center",
      dataIndex: "length",
      width: "3%",
      sorter: {
        compare: (a, b) => a.length.localeCompare(b.length),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") return <div>{record.length}</div>;
      },
    },
    {
      title: language.uploadDate,
      align: "center",
      dataIndex: "uploadDate",
      width: "5%",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
      },
      render: (text, record, index) => {
        return <div>{moment(record.createdAt).format("LL")}</div>;
      },
    },
    {
      title: language.lastEdit,
      align: "center",
      width: "5%",
      sorter: {
        compare: (a, b) => a.lastEdit - b.lastEdit,
      },
      render: (record) => {
        return <div>{moment(record.lastEdit?.toDate()).format("LL")}</div>;
      },
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (record) => {
        switch (record.status) {
          case "TRANSCRIBING":
            return (
              <Tag icon={<SyncOutlined spin />} color="#108ee9">
                {language.transcribing}
              </Tag>
            );
          case "TRANSCRIBED":
            return (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/admin/transcripts/edit/${record.id}`);
                }}
              >
                <Tag color="#87d068">{language.ready}</Tag>
              </a>
            );
          case "ERROR":
            return <Tag color="#ff0000">{language.error}</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: language.action,
      align: "center",
      width: "2%",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {record.status === "TRANSCRIBED" && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/admin/transcripts/edit/${record.id}`);
                }}
              >
                <Button type="link" icon={<EyeOutlined />}>
                  {language.btnView}
                </Button>
              </a>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        <Card style={{ marginBottom: 18 }}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify="space-between"
          >
            <Col className="gutter-row" span={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h2>{language.transcripts}</h2>
              </div>
            </Col>
          </Row>
        </Card>
        <Table
          bordered
          columns={columns}
          dataSource={files}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </Layout>
  );
};

export default Users;
