import React, { useState, useEffect, useRef } from "react";

import { Card, Table, Layout, Button, Col, Input, Space, Modal } from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router";

import "./styles.less";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import moment from "moment-timezone";
import { db, storage } from "utils/firebase";
import { firestore } from "firebase";
import useAuth from "hooks/useAuth";

const { confirm } = Modal;

const Meetings = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { user } = useAuth();

  const searchInputEl = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const convertToDuration = (value) => {
    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.search}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: (text) => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  text[dataIndex] ? text[dataIndex].toString() : ""
                }
              />
            </div>
          </div>
        </div>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    const start = async () => {
      db.collection("meetings")
        .where("tenantId", "==", process.env.REACT_APP_TENANT_ID)
        .where("userId", "==", user.uid)
        .onSnapshot(async (querySnapshot) => {
          setMeetings((prev) => []);

          await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              db.collection("meetings")
                .doc(doc.id)
                .collection("speakers")
                .onSnapshot((value) => {
                  if (!value.empty) {
                    const createdAt = doc.data().start_timestamp
                      ? firestore.Timestamp.fromDate(
                          moment.unix(doc.data().start_timestamp).toDate()
                        )
                      : firestore.Timestamp.now();
                    setMeetings((prev) => [
                      ...prev.filter((item) => item.id !== doc.id),
                      {
                        createdAt: createdAt,
                        lastEdited: doc.data().lastEdited
                          ? doc.data().lastEdited
                          : createdAt,
                        ...doc.data(),
                        id: doc.id,
                        name: doc.id.includes("_")
                          ? doc.id.split("_").slice(0, -1).join("_")
                          : doc.id,
                        participants: value.docs
                          .map((doc) => doc.id)
                          .sort((a, b) => a.localeCompare(b))
                          .join(", "),
                      },
                    ]);
                  }
                });
            })
          );
        });
    };

    start();
    setLoading(false);
  }, []);

  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>{lang.meeting}</div>
            <div>{lang.date}</div>
          </div>
        );
      },
      align: "center",
      width: "8%",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
      },
      defaultSortOrder: "descend",
      render: (record) => {
        return (
          <div>
            <div>
              {moment(record.createdAt?.toDate()).format("YYYY/M/D (ddd)")}
            </div>
            <div>{moment(record.createdAt?.toDate()).format("HH:mm:ss")}</div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>{lang.meeting}</div>
            <div>{lang.ID}</div>
          </div>
        );
      },
      width: "12%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: lang.participants,
      width: "30%",
      ...getColumnSearchProps("participants"),
    },
    {
      title: lang.length,
      align: "center",
      width: "8%",
      sorter: {
        compare: (a, b) => a.length - b.length,
      },
      render: (record) => {
        return (
          <div>
            <div>
              {record.length
                ? convertToDuration(record.length)
                : lang.inProgress}
            </div>
          </div>
        );
      },
    },
    {
      title: lang.lastEdit,
      align: "center",
      width: "8%",
      sorter: {
        compare: (a, b) => a.lastEdited - b.lastEdited,
      },
      render: (record) => {
        return (
          <div>
            <div>
              {moment(record.lastEdited?.toDate()).format("YYYY/M/D (ddd)")}
            </div>
            <div>{moment(record.lastEdited?.toDate()).format("HH:mm:ss")}</div>
          </div>
        );
      },
    },
    {
      title: lang.actions,
      align: "center",
      width: "5%",
      render: (record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {!record.length ? (
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(`/user/meetings/live/${record.id}`);
                }}
              >
                {lang.btnView}
              </Button>
            ) : (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  navigate(`/user/meetings/edit/${record.id}`);
                }}
              >
                {lang.btnEdit}
              </Button>
            )}
            <Button
              type="link"
              icon={<DeleteOutlined />}
              danger
              onClickCapture={() => {
                confirm({
                  title: lang.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    const delSubCollection = async (subCollectionName) =>
                      db
                        .collection("meetings")
                        .doc(record.id)
                        .collection(subCollectionName)
                        .get()
                        .then(async (querySnapshot) => {
                          await Promise.all(
                            querySnapshot.docs.map(async (doc) => {
                              await db
                                .collection("meetings")
                                .doc(record.id)
                                .collection(subCollectionName)
                                .doc(doc.id)
                                .delete();
                            })
                          );
                        });

                    async function del() {
                      await Promise.all([
                        delSubCollection("sentences"),
                        delSubCollection("sentencesEdited"),
                        delSubCollection("speakers"),
                      ]);

                      await db.collection("meetings").doc(record.id).delete();
                      await db.collection("meetings").doc(record.name).delete();

                      await storage
                        .ref(record.id)
                        .listAll()
                        .then(async (res) => {
                          // Delete first subfolders
                          await Promise.all(
                            res.prefixes.map(async (folderRef) => {
                              await folderRef.listAll().then(async (res) => {
                                await Promise.all(
                                  res.items.map(async (fileRef) => {
                                    await fileRef.delete();
                                  })
                                );
                              });
                            })
                          );

                          // Delete files
                          await Promise.all(
                            res.items.map(async (itemRef) => {
                              await itemRef.delete();
                            })
                          );
                        });
                    }

                    del();
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
              style={{ color: "red" }}
            >
              {lang.btnDelete}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          marginTop: 20,
          marginBottom: 24,
        }}
      >
        <Card style={{ marginBottom: 18 }} bordered={false}>
          <Col className="gutter-row" span={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h2>{lang.meetings}</h2>
            </div>
          </Col>
        </Card>
        <Card>
          <Table
            loading={loading}
            bordered
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={meetings}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
            }}
          />
        </Card>
      </Layout>
    </Layout>
  );
};

export default Meetings;
