export default {
  login: "Log In",
  logout: "Log Out",
  password: "Password",
  forgotPassword: "Forgot Password",
  changePassword: "Change Password",
  changePasswordSuccessTitle: "Change Password Successfully",
  changePasswordSuccessContent: "Your password has been changed successfully.",
  currentPassword: "Current Password",
  pleaseInputCurrentPassword: "Please enter your current password.",
  newPassword: "New Password",
  pleaseInputNewPassword: "Please enter your new password.",
  passwordErrorMinimum: "Password must have at least 8 characters",
  passwordErrorUppercase: "Password must have at least 1 Uppercase Letter",
  passwordErrorLowercase: "Password must have at least 1 Lowercase Letter",
  passwordErrorNumber: "Password must have at least 1 Number",
  slogan: "Automatic Speech Recognition",
  companyRights: "© 2022 NAMI TECHNOLOGY JSC, Inc. All rights reserved.",
  dictionary: "Dictionary",
  dictionarySuccess: "Dictionary has been saved successfully",
  word1: "Incorrect output",
  word2: "Incorrect yomigana",
  word3: "Correct output",
  word4: "Correct yomigana",
  word5: "Type",
  transcripts: "Transcripts",
  transcript: "Transcript",
  record: "Record",
  correctedTranscript: "Corrected Transcript",
  createdAt: "Created At",
  audio: "Audio",
  secLimit: "600 seconds limit",
  seconds: "seconds",
  predicting: "Predicting...",
  selectDragDropAudio: "Select or Drag and Drop Audio file",
  selectDragDropVideo: "Select or Drag and Drop audio/ video file",
  bugSuccess: "Bug has been created successfully",
  selectLanguage: "Select language",
  japanese: "Japanese",
  vietnamese: "Vietnamese",
  haveBugs: "Have bugs",
  noMessage: "You have no message!",
  placeholderPassword: "Password",
  loading: "Loading",
  user: "User",
  admin: "Admin",
  doYouWantToDeleteTheseItems: "Do you want to delete these items?",
  doYouWantToCancel: "Do you want to cancel?",
  btnSubmit: "Submit",
  btnStop: "Stop",
  requiredPassword: "Password is required",
  btnCancel: "Cancel",
  btnUpdate: "Update",
  btnAdd: "Add",
  btnEdit: "Edit",
  btnDelete: "Delete",
  btnSave: "Save",
  btnRecord: "Record",
  requiredEmail: "Email is required",
  email: "Email",
  emailRequirePlaceholder: "Please enter an email",
  recordAudio: "Record audio",
  name: "Name",
  phone: "Phone",
  active: "Active",
  inactive: "In-active",
  word1Required: "Incorrect output is required",
  word2Required: "Incorrect yomigana is required",
  word3Required: "Correct output is required",
  word4Required: "Correct yomigana is required",
  word5Required: "Type is required",
  connectionStatus: "Connection Status",
  closed: "Closed",
  connecting: "Connecting",
  connected: "Connected",
  live: "Live",
  offline: "Offline",
  btnStart: "Start",
  successSave: "Saved successfully",
  btnDownload: "Download",
  btnUpload: "Upload",
  line: "line",
  uploadFail: "upload failed!",
  uploadSuccess: "lines has been uploaded successfully!",
  meetings: "Meetings",
  btnView: "View",
  actions: "Actions",
  ID: "ID",
  date: "Datetime",
  export: "Export file",
  exportAll: "Export JSON",
  exportSelected: "Export selected",
  revert: "Revert",
  play: "Play",
  pause: "Pause",
  jumpTo: "Jump to",
  jumpToTimestamp: "Jump to timestamp",
  speed: "Speed",
  volume: "Volume",
  setVolume: "Set volume",
  notes: "Notes",
  writeNotesHere: "Write your notes here",
  participants: "Participants",
  length: "Length",
  meeting: "Meeting ",
  lastEdit: "Last Edit",
  sortBy: "Order",
  sortByRequired: "Order is required",
  inProgress: "In Progress...",
  filter: "Filter",
  showAll: "Show all",
  showOnlySelected: "Show only selected",
  dev: "Dev",
  transcriptionStarted: "Transcription started",
  transcriptionStopped: "Transcription stopped",
  replacedDict: "Replaced dictionary",
  hour: "Hour",
  minute: "Minute",
  second: "Second",
  apply: "Apply",
  jumpToNewTimestampSuccess: "Jump to new timestamp successfully",
  jumpToEndSuccess: "Jump to end successfully",
  saveSuccess: "Save successfully",
  saveFail: "Save failed",
  uploadDate: "Upload date",
  status: "Status",
  pasteVideo: "Or Paste a video link here",
  downloading: "Downloading",
  uploading: "Uploading",
  transcribing: "Transcribing",
  transcribed: "Transcribed",
  ready: "Ready",
  action: "Action",
  success: "Success",
  error: "Error",
  fileTranscriptSettings:
    "Please provide number of speaker(s) and setting of your audio/ video file(s):",
  onePerson: "1 person",
  twoPeopleMonoChannel: "2 people, mono channel file",
  twoPeopleStereoChannels: "2 people, stereo channel file",
  twoPeopleMonoChannelWithoutOverlap:
    "2 people, mono channel without talk overlap",
  rangeOfSpeakers: "Range of speaker(s)",
  person: "Person",
  people: "People",
  statistics: "Statistics",
  daily: "Daily",
  monthly: "Monthly",
  search: "Search",
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  thisMonth: "This month",
  thisQuarter: "This quarter",
  thisYear: "This year",
  total: "Total time",
  minutes: "minutes",
  hours: "hours",
  totalCost: "Total cost",
  otherDict: "OTHER",
  organizationDict: "ORGANIZATION",
  personDict: "PERSON",
  locationDict: "LOCATION",
  wrongEmailPassword: "Wrong Email or Password",
  wrongEmailFormat: "Email Wrong Format",
  resetPasswordSuccess: "Reset password link has been sent to your email",
  userNotFound: "User Not Found",
  backToLogin: "Go back to Log In",
  meetingClose: "Meeting closed",
  deleteSuccess: "Delete successfully",
  deleteFail: "Delete failed",
  uploaded: "Uploaded",
  callDate: "Call date",
  pickUp: "Pick up",
  dontPickUp: "Don't pick up",
  internal: "Internal",
  external: "External",
  caller: "Caller",
  callee: "Callee",
  filterResults: "filter results",
  searchResults: "search results",
  results: "results",
  keyword: "Keyword",
  minDuration: "Min Duration",
  advancedSearch: "Advanced Search",
  hideAdvancedSearch: "Hide Advanced Search",
  fromNumber: "From Number",
  toNumber: "To Number",
  agentNumber: "Agent Number",
  calls: "Calls",
  showMore: "Show more",
  showLess: "Show less",
  all: "All",
  uploadDateFuture: "Upload date must be today or in the past",
  followUp: "Follow Up Calls",
  followUpNumber: "Follow Up Calls Number",
  totalWords: "Total Words",
  phoneNumber: "Phone Number",
  noAudioFound: "No Audio Found",
  noMetadataFound: "No Metadata Found",
  noSpeechData: "No Speech Data",
  monitor: "Monitor",
  totalLines: "Total: {{lines}} lines",
};
