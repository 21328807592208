import { Input, InputNumber } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Editor, Range } from "slate";
import { useFocused, useSlate } from "slate-react";
import { formatTimeToString } from "utils/utils";

const Portal = ({ children }) => {
  return typeof document === "object"
    ? createPortal(children, document.body)
    : null;
};

const SentenceTimestampUpdateFormat = React.memo(({ sentence, index }) => {
  const editor = useSlate();
  const [start, setStart] = useState(formatTimeToString(sentence.start, true));
  const [end, setEnd] = useState(formatTimeToString(sentence.end, true));
  const handleUpdate = useRef();

  if (!sentence) {
    setStart(sentence.start);
    setEnd(sentence.end);
    return null;
  }

  handleUpdate.current = (start, end) => {
    if (start === null || end === null) return;

    editor.apply({
      type: "update_sentence_timestamp",
      sentenceIndex: index.current,
      withFormat: true,
      start,
      end,
    });
  };

  return (
    <>
      <Input
        value={start}
        onChange={(e) => {
          setStart(e.target.value);
          handleUpdate.current(e.target.value, end);
        }}
        style={{ display: "inline-block", width: "160px" }}
      />
      <Input
        value={end}
        onChange={(e) => {
          setEnd(e.target.value);
          handleUpdate.current(start, e.target.value);
        }}
        style={{ display: "inline-block", width: "160px" }}
      />
    </>
  );
});

const SentenceTimestampUpdate = React.memo(({ sentence, index }) => {
  const editor = useSlate();
  const [start, setStart] = useState(sentence.start);
  const [end, setEnd] = useState(sentence.end);
  const handleUpdate = useRef();

  if (!sentence) {
    setStart(sentence.start);
    setEnd(sentence.end);
    return null;
  }

  handleUpdate.current = () => {
    if (start === null || end === null) return;

    editor.apply({
      type: "update_sentence_timestamp",
      sentenceIndex: index.current,
      start,
      end,
    });
  };

  useEffect(() => {
    return () => {
      handleUpdate.current();
    };
  }, [handleUpdate]);

  return (
    <>
      <InputNumber
        value={start}
        min={0}
        onChange={(value) => {
          setStart(value);
          handleUpdate.current();
        }}
      />
      <InputNumber
        value={end}
        min={0}
        onChange={(value) => {
          setEnd(value);
          handleUpdate.current();
        }}
      />
    </>
  );
});

const DataToolTranscriptToolbar = ({ withFormat }) => {
  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();
  // const [sentence, setSentence] = useState(null);
  const sentenceIndex = useRef();
  const [show, setShow] = useState(false);

  const sentence =
    editor.selection &&
    editor.children[Range.start(editor.selection).path[0]].sentence;

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      setShow(false);
      el.removeAttribute("style");
      return;
    }

    const start = Range.start(selection);
    const sentence = editor.children[start.path[0]].sentence;

    if (sentence) {
      sentenceIndex.current = start.path[0];
      // document.getElementById("popup-play--text").innerText = " " + wordAtIndex.start;
    }

    try {
      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = "1";
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 8}px`;
      el.style.left = `${
        rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
      }px`;
    } catch {}
    setShow(true);
  }, [editor.selection, inFocus]);

  return (
    <Portal>
      <div ref={ref} className="transcriptEditor-toolbar">
        {show && sentence && (
          <>
            {withFormat ? (
              <SentenceTimestampUpdateFormat
                index={sentenceIndex}
                sentence={sentence}
              />
            ) : (
              <SentenceTimestampUpdate
                index={sentenceIndex}
                sentence={sentence}
              />
            )}
          </>
        )}
      </div>
    </Portal>
  );
};

export default React.memo(DataToolTranscriptToolbar);
