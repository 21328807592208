import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Dropdown, Badge, Button, Modal, Form, Input, Col, Row, Affix } from "antd";
import { BellOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";

import "./styles.less";

import logo from "assets/Color2.png";
import funixLogo from "assets/funix-logo.png";

import { useRecoilState } from "recoil";
import { localeAtom } from "recoils/atoms";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import iconFlagEn from "assets/icons/united-states-flag.png";
import iconFlagVi from "assets/icons/vietnam-flag.png";
import iconFlagJa from "assets/icons/japan-flag.png";
import useAuth from "hooks/useAuth";

import { auth } from "utils/firebase";
import firebase from "firebase";
import { IS_FUNIX } from "utils/constants";

const { Header } = Layout;

const { REACT_APP_TENANT_ID } = process.env;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EmployerLayout = () => {
  //check require admin login
  useAuth();
  let navigate = useNavigate();

  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locale, setLocale] = useRecoilState(localeAtom);

  const languageSelected = (locale) => {
    switch (locale) {
      case "vi":
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
      case "en":
        return <img src={iconFlagEn} width={30} alt="English Language" />;
      case "ja":
        return <img src={iconFlagJa} width={30} alt="Japanese Language" />;
      default:
        return <img src={iconFlagVi} width={30} alt="Vietnamese Language" />;
    }
  };

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const pathMenu = useLocation().pathname.split("/");
  const [selectedKey, setSelectedKey] = useState();

  useEffect(() => {
    if (locale === "null") {
      setLocale(process.env.REACT_APP_DEFAULT_LANG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (locale) => {
    setLocale(locale);
  };

  useEffect(() => {
    if (pathMenu[2] !== undefined) {
      setSelectedKey(pathMenu[2].trim());
    } else {
      setSelectedKey("dashboard");
    }
  }, [pathMenu]);

  const menu = (
    <Menu style={{ marginTop: 10 }}>
      <Menu.Item onClick={() => setChangePasswordModal(true)}>{lang.changePassword}</Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        onClick={() => {
          auth.tenantId = REACT_APP_TENANT_ID;
          auth.signOut();
        }}
      >
        {lang.logout}
      </Menu.Item>
    </Menu>
  );

  const notify = (
    <Menu>
      <Menu.Item>{lang.noMessage}</Menu.Item>
    </Menu>
  );

  const languages = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage("ja")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagJa} width={24} alt="Japanese Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>日本語</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage("en")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagEn} width={24} alt="English Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>English</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => changeLanguage("vi")}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <img src={iconFlagVi} width={24} alt="Vietnam Language" />
          </div>
          <div style={{ marginLeft: 8, marginTop: 2 }}>Tiếng Việt</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="employer-layout">
      <Layout>
        <Affix offsetTop={0}>
          <Header className="header">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 140 }}>
                  {IS_FUNIX ? (
                    <img src={funixLogo} alt="logo" style={{ width: "100%", marginBottom: "5px" }} />
                  ) : (
                    <img src={logo} alt="logo" style={{ width: "120%" }} />
                  )}
                </div>
                <div style={{ flex: 1, marginLeft: 80 }}>
                  <Menu mode="horizontal" defaultSelectedKeys={[selectedKey]} key={selectedKey}>
                    <Menu.Item style={{ marginRight: 20 }} onClick={() => navigate(`/admin/users`)} key="users">
                      {lang.user}
                    </Menu.Item>
                    <Menu.Item
                      style={{ marginRight: 20 }}
                      onClick={() => navigate(`/admin/transcripts`)}
                      key="transcripts"
                    >
                      {lang.transcripts}
                    </Menu.Item>
                    <Menu.Item style={{ marginRight: 20 }} onClick={() => navigate(`/admin/dicts`)} key="dicts">
                      {lang.dictionary}
                    </Menu.Item>
                  </Menu>
                </div>
              </div>
              <div
                style={{
                  height: "64px",
                  width: "100%",
                  backgroundColor: "white",
                  display: "flex",
                  flexFlow: "row-reverse",
                  alignItems: "center",
                }}
              >
                <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold", marginRight: 5 }}>Admin</span>
                    <DownOutlined style={{ fontSize: "22px" }} theme="outlined" />
                  </div>
                </Dropdown>
                <Dropdown overlay={notify} placement="bottomRight">
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginRight: "25px",
                    }}
                  >
                    <Badge count={0}>
                      <BellOutlined style={{ fontSize: "22px", color: "#ccc" }} theme="filled" />
                    </Badge>
                  </div>
                </Dropdown>
                <div style={{ position: "relative" }}>
                  <Dropdown overlay={languages} placement="bottom">
                    <div
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        marginRight: 16,
                        width: 100,
                      }}
                    >
                      {languageSelected(locale)}
                      <DownOutlined style={{ fontSize: 10, marginLeft: 8 }} />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Header>
        </Affix>
        <Layout>
          <Outlet />
        </Layout>
      </Layout>
      <Modal
        visible={changePasswordModal}
        title={lang.changePassword}
        onCancel={() => setChangePasswordModal(false)}
        destroyOnClose={true}
        centered
        footer={null}
      >
        <Form
          {...layout}
          name="changePasswordForm"
          className="change-password-form"
          labelAlign="left"
          onFinish={async (values) => {
            const credential = firebase.auth.EmailAuthProvider.credential(
              auth.currentUser.email,
              values.currentPassword
            );
            await auth.currentUser.reauthenticateWithCredential(credential);
            await auth.currentUser.updatePassword(values.newPassword).then(() => {
              setChangePasswordModal(false);
              Modal.success({
                title: lang.changePasswordSuccessTitle,
                content: lang.changePasswordSuccessContent,
              });
            });
          }}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="currentPassword"
            label={lang.currentPassword}
            rules={[{ required: true, message: lang.pleaseInputCurrentPassword }]}
          >
            <Input.Password type="password" placeholder={lang.currentPassword} />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label={lang.newPassword}
            rules={[
              {
                required: true,
                message: lang.pleaseInputNewPassword,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value === undefined) return Promise.resolve();

                  if (value.length < 8) return Promise.reject(lang.passwordErrorMinimum);

                  var hasUpperCase = /[A-Z]/.test(value);
                  var hasLowerCase = /[a-z]/.test(value);
                  var hasNumbers = /\d/.test(value);
                  //var hasNonalphas = /\W/.test(value);

                  if (hasUpperCase && hasLowerCase && hasNumbers) {
                    return Promise.resolve();
                  } else {
                    let msg = "";
                    if (hasUpperCase === false) {
                      msg = `${msg} ${lang.passwordErrorUppercase} `;
                    }

                    if (hasLowerCase === false) {
                      msg = `${msg} ${lang.passwordErrorLowercase} `;
                    }

                    if (hasNumbers === false) {
                      msg = `${msg} ${lang.passwordErrorNumber} `;
                    }

                    return Promise.reject(msg);
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder={lang.newPassword} />
          </Form.Item>
          <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
            <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
              <Col className="gutter-row" span={24} style={{ textAlign: "right", paddingRight: 20 }}>
                <Button type="primary" htmlType="submit" style={{ fontWeight: "bold" }}>
                  {lang.btnUpdate}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default EmployerLayout;
