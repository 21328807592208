import React, { useState, useRef } from "react";
import { Layout, Card, Row, Col, Button, Radio, Space } from "antd";
import "./styles.less";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";
import useRecorder from "./useRecorder";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import UserTranscriptsLayout from "../../../components/web/Layout/UserTranscripts";
import _ from "lodash";
import useKkodeRecorder from "./useKkodeRecorder";

const socketViUrl = process.env.REACT_APP_LIVE_API_VI_URL;
const socketJpUrl =
  "wss://apikey:j4brmqdj6wdrg310@jppoc.namisense.ai/instances/bjnctxhlv7kauffd/v1/recognize?model=ja-JP_Multimedia&nami-learning-opt-out=true&sample_rate=16000";
const socketThaiUrl = "wss://demothai.kkode.com/ws/streamvad/16000";
const socketIndoUrl = "wss://demoindo.kkode.com/ws/streamvad/16000";
const socketStreamingASRUrl = "wss://jp-asr-api.namisense.ai/stream_asr_vn";

const StreamingTranscript = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [selectedLang, setSelectedLang] = useState("vi");

  const [thirdPartyMessage, setThirdPartyMessage] = useState("");

  const [fullContextMessageHistory, setFullContextMessageHistory] = useState(
    []
  );
  const [streamingASRMessageHistory, setStreamingASRMessageHistory] = useState(
    []
  );

  const fullContextSocket = useRef(null);
  const streamingASRSocket = useRef(null);
  const thirdPartySocket = useRef(null);

  let [, startThirdPartyRecording, stopThirdPartyRecording] =
    useKkodeRecorder(thirdPartySocket);
  let [, startFullContextRecording, stopFullContextRecording] =
    useRecorder(fullContextSocket);
  let [, startStreamingASRRecording, stopStreamingASRRecording, audioURL] =
    useRecorder(streamingASRSocket);

  const [startRec, setStartRec] = useState(false);

  const [thirdPartyConnectState, setThirdPartyConnectState] =
    useState("Connecting");
  const [fullContextConnectState, setFullContextConnectState] =
    useState("Connecting");
  const [streamingASRConnectState, setStreamingASRConnectState] =
    useState("Connecting");

  const startRecord = () => {
    if (selectedLang !== "vi" && selectedLang !== "jp") {
      if (startRec) {
        stopThirdPartyRecording();
        if (thirdPartySocket.current) thirdPartySocket.current.close();
        thirdPartySocket.current = null;
        setStartRec(false);
      } else {
        thirdPartySocket.current = new WebSocket(
          selectedLang === "thai" ? socketThaiUrl : socketIndoUrl
        );

        thirdPartySocket.current.addEventListener("open", () => {
          setThirdPartyConnectState("Connected");
        });

        thirdPartySocket.current.addEventListener("close", () => {
          setThirdPartyConnectState("Closed");
        });

        thirdPartySocket.current.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.result) {
            setThirdPartyMessage(data.result);
          }
        });

        setThirdPartyMessage("");
        setStartRec(true);
        startThirdPartyRecording();
      }
    } else {
      if (startRec) {
        if (fullContextSocket.current) {
          fullContextSocket.current.close();
          fullContextSocket.current = null;
        }
        if (streamingASRSocket.current) {
          streamingASRSocket.current.close();
          streamingASRSocket.current = null;
        }
        setStartRec(false);
        stopFullContextRecording();
        stopStreamingASRRecording();
      } else {
        // Full context socket
        fullContextSocket.current = new WebSocket(
          selectedLang === "jp" ? socketJpUrl : socketViUrl
        );

        fullContextSocket.current.addEventListener("open", () => {
          setFullContextConnectState("Connected");
        });

        fullContextSocket.current.addEventListener("close", () => {
          setFullContextConnectState("Closed");
        });

        fullContextSocket.current.addEventListener("message", (event) => {
          if (selectedLang === "vi") {
            setFullContextMessageHistory((prev) =>
              prev.concat(JSON.parse(event.data))
            );
          } else {
            const data = JSON.parse(event.data);
            const transcript = data.results?.[0]?.alternatives?.[0]?.transcript;
            if (transcript)
              setFullContextMessageHistory((prev) => prev.concat(transcript));
          }
        });
        setFullContextMessageHistory([]);
        startFullContextRecording();

        if (selectedLang === "vi") {
          // Streaming ASR socket
          streamingASRSocket.current = new WebSocket(socketStreamingASRUrl);

          streamingASRSocket.current.addEventListener("open", () => {
            setStreamingASRConnectState("Connected");
          });

          streamingASRSocket.current.addEventListener("close", () => {
            setStreamingASRConnectState("Closed");
          });

          streamingASRSocket.current.addEventListener("message", (event) => {
            const data = JSON.parse(event.data);
            setStreamingASRMessageHistory((oldMessages) =>
              !data.end_sentence && !data.transcript
                ? oldMessages
                : oldMessages.length === 0 || _.last(oldMessages).end_sentence
                ? data.transcript
                  ? oldMessages.concat(data)
                  : oldMessages
                : oldMessages.map((sentence, i) =>
                    i === oldMessages.length - 1 && data.transcript
                      ? data
                      : { ...sentence, end_sentence: data.end_sentence }
                  )
            );
          });

          setStreamingASRMessageHistory([]);
          startStreamingASRRecording();
        }

        setStartRec(true);
      }
    }
  };

  return (
    <>
      <UserTranscriptsLayout />
      <Layout className="jobs-page">
        <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Card style={{ marginBottom: 18 }} bordered={false}>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              justify="space-between"
            >
              <Col className="gutter-row" span={12}>
                Select languague:
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24} style={{ position: "relative" }}>
                <Radio.Group
                  disabled={startRec}
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <Space direction="vertical">
                    <Radio value={"vi"}>Vietnamese</Radio>
                    <Radio value={"jp"}>Japanese</Radio>
                    <Radio value={"thai"}>Thai</Radio>
                    <Radio value={"indo"}>Indonesian</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
            <Card style={{ marginTop: "20px" }}>
              <p style={{ textAlign: "center" }}>
                {startRec ? (
                  <a onClick={startRecord}>
                    <PauseCircleFilled
                      style={{ fontSize: 24, color: "#BD271E" }}
                    />
                    <span
                      style={{ fontSize: 24, color: "#BD271E", marginLeft: 10 }}
                    >
                      {lang.btnStop}
                    </span>
                  </a>
                ) : (
                  <a onClick={startRecord}>
                    <PlayCircleFilled
                      style={{ fontSize: 24, color: "#BD271E" }}
                    />
                    <span
                      style={{
                        fontSize: 24,
                        color: "#dBD271E",
                        marginLeft: 10,
                      }}
                    >
                      {lang.btnStart}
                    </span>
                  </a>
                )}
                <br />
              </p>
            </Card>
            {audioURL && false && (
              <Button
                onClick={() => {
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = audioURL;
                  a.download = "audio_data.webm";
                  a.click();
                }}
                type="primary"
                style={{ marginTop: "20px" }}
              >
                Download audio file
              </Button>
            )}
          </Card>
          <Row style={{ paddingBottom: "24px" }}>
            {selectedLang === "vi" || selectedLang === "jp" ? (
              <>
                <Col span={selectedLang === "jp" ? 24 : 12}>
                  <Card style={{ height: "100%" }}>
                    <p>Full context transcript</p>
                    <p>
                      {lang.connectionStatus}:{" "}
                      {fullContextConnectState === "Connected" ? (
                        <span style={{ fontWeight: "bold", color: "green" }}>
                          {lang.connected}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "bold", color: "red" }}>
                          {lang.closed}
                        </span>
                      )}
                    </p>
                    <Card>
                      {fullContextMessageHistory.map((item, index) => {
                        return <p key={index}>{item}</p>;
                      })}
                    </Card>
                  </Card>
                </Col>
                {selectedLang === "vi" && (
                  <Col span={12}>
                    <Card style={{ height: "100%" }}>
                      <p>Streaming ASR transcript</p>
                      <p>
                        {lang.connectionStatus}:{" "}
                        {streamingASRConnectState === "Connected" ? (
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {lang.connected}
                          </span>
                        ) : (
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            {lang.closed}
                          </span>
                        )}
                      </p>
                      <Card>
                        {streamingASRMessageHistory.map((item, index) => {
                          return <p key={index}>{item.transcript}</p>;
                        })}
                      </Card>
                    </Card>
                  </Col>
                )}
              </>
            ) : (
              <Col span={24}>
                <Card style={{ height: "100%" }}>
                  <p>
                    {lang.connectionStatus}:{" "}
                    {thirdPartyConnectState === "Connected" ? (
                      <span style={{ fontWeight: "bold", color: "green" }}>
                        {lang.connected}
                      </span>
                    ) : (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {lang.closed}
                      </span>
                    )}
                  </p>
                  <Card>{thirdPartyMessage}</Card>
                </Card>
              </Col>
            )}
          </Row>
        </Layout>
      </Layout>
    </>
  );
};

export default StreamingTranscript;
