import React from "react";
import {
  DownloadOutlined,
  RetweetOutlined,
  SaveOutlined,
  UploadOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  HistoryOutlined,
  CheckOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Skeleton,
  Space,
  Spin,
  Upload,
  message,
  Modal,
  Drawer,
  Card,
  Checkbox,
} from "antd";
import SafariModal from "components/SafariModal";
import {
  cleanSentences,
  cleanSentencesDataTool,
} from "components/TranscriptEditor/util";
import _ from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { HISTORY_LIMIT, IS_SAFARI } from "utils/constants";
import { db } from "utils/firebase";
import { getLanguages } from "utils/lang";
import {
  exportDataTimestamp,
  exportToCSVFile,
  exportToJsonFile,
  exportToSRTFile,
  exportToSRTWithSpeakerFile,
  exportToTxtFile,
  speakerToString,
} from "utils/utils";
import useDataToolRole from "hooks/useDataToolRole";
import { getTranscriptEditedDocId } from "utils/editor";
import moment from "moment-timezone";

const { confirm } = Modal;

const MeetingDetailHeader = ({
  fileMetadata,
  setSentences,
  exceedDeadline,
  isLastRoleSubmitted,
  autoSave,
  setAutoSave,
  notes,
  setSpeakers,
  setComparingSentences,
  localSentences,
  submitted,
  showCompare,
  setShowCompare,
  setEdited,
  setSubmitted,
  edited,
  comparingSentences,
  versionHistory,
  setVersionHistory,
  handleSave,
  initEditor,
  exportSentences,
  pauseAtEnd,
  setPauseAtEnd,
}) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const [safariModal, setSafariModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [uploadPreviewStatus, setUploadPreviewStatus] = useState(null);
  const [showVersionHistory, setShowVersionHistory] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dataToolRole = useDataToolRole();

  const oldSentences = useRef(null);

  const { fileId } = useParams();

  const fileLabel = fileMetadata?.name;

  const readOnly = submitted || exceedDeadline;

  const handleExport = (index) => {
    const baseFileName = fileLabel;
    exportSentences.forEach(({ label, sentences }) => {
      const fileName = baseFileName + (label ? `_${label}` : "");
      switch (index) {
        case 0:
          exportToJsonFile(
            dataToolRole
              ? cleanSentencesDataTool(sentences)
              : cleanSentences(sentences),
            fileName
          );
          break;
        case 1:
          exportToCSVFile(
            dataToolRole
              ? cleanSentencesDataTool(sentences)
              : cleanSentences(sentences),
            fileName
          );
          break;
        case 2:
          exportDataTimestamp(
            dataToolRole
              ? cleanSentencesDataTool(sentences)
              : cleanSentences(sentences),
            fileName
          );
          break;
        case 3:
          exportToSRTFile(sentences, fileName);
          break;
        case 4:
          exportToSRTWithSpeakerFile(sentences, fileName);
          break;
        case 5:
          exportToTxtFile(sentences, fileName);
          break;
        default:
          break;
      }
    });
  };

  const exportMenu = (
    <Menu>
      {[
        lang.exportAll,
        "Export CSV",
        "Export Timestamp",
        "Export SRT",
        "Export SRT with speaker",
        "Export TXT",
      ].map((item, index) => (
        <Menu.Item
          key={`download-item-${index}`}
          onClick={() => handleExport(index)}
        >
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );

  const revertTranscriptionHandle = async () => {
    initEditor.current = false;
    setLoadingRevert(true);
    try {
      if (!oldSentences.current) {
        const legacySentences = await db
          .collection("files")
          .doc(fileId)
          .collection("legacySentences")
          .orderBy("start", "asc")
          .get();
        if (legacySentences.empty) {
          let sentences;
          if (dataToolRole === "reviewer") {
            const annotatorEdited = await db
              .collection("files")
              .doc(fileId)
              .collection("edited")
              .doc("annotator")
              .get();
            if (annotatorEdited.exists) {
              sentences = annotatorEdited.data().sentences;
            } else {
              throw new Error("Annotator data not found");
            }
          } else if (dataToolRole === "approver") {
            const reviewerEdited = await db
              .collection("files")
              .doc(fileId)
              .collection("edited")
              .doc("reviewer")
              .get();
            if (reviewerEdited.exists) {
              sentences = reviewerEdited.data().sentences;
            } else {
              throw new Error("Reviewer data not found");
            }
          } else {
            sentences = (
              await db
                .collection("files")
                .doc(fileId)
                .collection("sentences")
                .orderBy("start", "asc")
                .get()
            ).docs.map((doc) => doc.data());
          }
          oldSentences.current = sentences;
        } else {
          oldSentences.current = legacySentences.docs.map((doc) => doc.data());
        }
      }
      const speakers = {};
      oldSentences.current.forEach((sentence) => {
        const speaker = speakerToString(sentence.speaker);
        speakers[speaker] = {
          label: speaker,
          show: true,
          unused: false,
        };
      });
      setSpeakers(speakers);

      setSentences(_.cloneDeep(oldSentences.current));
      message.success("Transcript is reverted");
    } catch (e) {
      console.log(e);
      message.error("Failed to load old transcript: " + e?.message);
    } finally {
      setLoadingRevert(false);
    }
  };

  const saveTranscriptionHandle = async (showMessage = true) => {
    setSaveLoading(true);
    try {
      await db.runTransaction(async (transaction) => {
        const fileDocRef = db.collection("files").doc(fileId);
        transaction.update(fileDocRef, { notes });

        const editedDocRef = fileDocRef
          .collection("edited")
          .doc(getTranscriptEditedDocId(dataToolRole));
        const sortedNewSentences = dataToolRole
          ? cleanSentencesDataTool(localSentences.current)
          : cleanSentences(localSentences.current, true);
        transaction.set(
          editedDocRef,
          { sentences: sortedNewSentences },
          { merge: true }
        );
        if (dataToolRole === "annotator") {
          transaction.update(fileDocRef, { annotatorEdited: true });
        }
        if (dataToolRole === "reviewer") {
          transaction.update(fileDocRef, { reviewerEdited: true });
        }
        if (dataToolRole === "approver") {
          transaction.update(fileDocRef, { approverEdited: true });
        }
        const createdAt = Date.now();
        const tmpHistory = _.cloneDeep(versionHistory);
        if (tmpHistory.length === HISTORY_LIMIT) {
          const removeHistory = tmpHistory.pop();
          transaction.delete(
            editedDocRef.collection("versions").doc(removeHistory.id)
          );
        }
        const versionDocRef = editedDocRef.collection("versions").doc();
        tmpHistory.unshift({
          createdAt,
          id: versionDocRef.id,
        });
        transaction.update(editedDocRef, { versionHistory: tmpHistory });
        transaction.set(
          versionDocRef,
          { sentences: sortedNewSentences },
          { merge: true }
        );
        setVersionHistory(tmpHistory);
      });
      showMessage && message.success("Save transcript successfully");
      setEdited(true);
    } catch (e) {
      console.log(e);
      showMessage && message.error("Failed to save transcript " + e?.message);
    } finally {
      setSaveLoading(false);
    }
  };

  handleSave.current = saveTranscriptionHandle;

  const handleSubmit = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Submit",
      content:
        "Are you sure? You won't be able to edit the transcript after submitting, please be careful!",
      onOk: async () => {
        const data = {};

        if (dataToolRole === "annotator") {
          data.annotatorSubmitted = true;
          data.annotatorSubmittedAt = Date.now();
        }

        if (dataToolRole === "reviewer") {
          data.reviewerSubmitted = true;
          data.reviewerSubmittedAt = Date.now();
        }

        if (dataToolRole === "approver") {
          data.approverSubmitted = true;
          data.approverSubmittedAt = Date.now();
        }

        try {
          await db.collection("files").doc(fileId).update(data);
          setSubmitted(true);
          message.success("Submit successfully");
        } catch (err) {
          message.error("Fail to submit: " + err.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Skeleton active loading={!fileMetadata}>
      {contextHolder}
      <Row className="edit-header" justify="space-between" align="middle">
        <div className="edit-header-title">
          <span
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <b>{fileLabel}</b>
          </span>
          {IS_SAFARI && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="warning">Warning for Safari users!</span>
                <Button
                  type="link"
                  className="edit-header-warning"
                  style={{
                    display: "inline-block",
                  }}
                  onClick={() => setSafariModal(true)}
                >
                  See instruction
                </Button>
              </div>
              <SafariModal
                show={safariModal}
                onClose={() => setSafariModal(false)}
              />
            </>
          )}
        </div>
        <Col style={{ padding: "0 2rem" }}>
          {isLastRoleSubmitted && (
            <Space>
              <Checkbox
                checked={autoSave}
                onChange={(e) => {
                  setAutoSave(e.target.checked);
                }}
              >
                Autosave
              </Checkbox>
              <Checkbox
                checked={pauseAtEnd}
                onChange={(e) => {
                  setPauseAtEnd(e.target.checked);
                }}
              >
                Pause at end
              </Checkbox>
              {dataToolRole !== "admin" && !submitted && (
                <>
                  <Button
                    onClick={() => setShowVersionHistory(true)}
                    size="large"
                    type="link"
                    icon={<HistoryOutlined />}
                  >
                    Version history
                  </Button>
                  <Drawer
                    title="Version history"
                    placement="right"
                    onClose={() => setShowVersionHistory(false)}
                    visible={showVersionHistory}
                  >
                    {versionHistory.length === 0 ? (
                      <div>Your version history is empty. Save to add one.</div>
                    ) : (
                      <Space style={{ width: "100%" }} direction="vertical">
                        {versionHistory.map((history) => (
                          <Card
                            onClick={async () => {
                              const historyData = (
                                await db
                                  .collection("files")
                                  .doc(fileId)
                                  .collection("edited")
                                  .doc(getTranscriptEditedDocId(dataToolRole))
                                  .collection("versions")
                                  .doc(history.id)
                                  .get()
                              ).data();
                              if (historyData?.sentences) {
                                initEditor.current = false;
                                const speakers = {};
                                historyData.sentences.forEach((sentence) => {
                                  const speaker = speakerToString(
                                    sentence.speaker
                                  );
                                  speakers[speaker] = {
                                    label: speaker,
                                    show: true,
                                    unused: false,
                                  };
                                });
                                setSpeakers(speakers);

                                setSentences(historyData.sentences);
                                setShowVersionHistory(false);
                                message.success("Version loaded");
                              } else {
                                message.error("Failed to load version data");
                              }
                            }}
                            hoverable
                            key={history.id}
                          >
                            {moment(history.createdAt).format("MMM D, h:mm A")}
                          </Card>
                        ))}
                      </Space>
                    )}
                  </Drawer>
                </>
              )}
              {(!dataToolRole || dataToolRole === "tool") && (
                <Upload
                  progress={{ style: { display: "none" } }}
                  maxCount={1}
                  // Accept JSON or SRT file
                  accept="application/JSON, .srt"
                  showUploadList={false}
                  customRequest={({ file, onSuccess, onError }) => {
                    try {
                      const reader = new FileReader();
                      reader.readAsText(file);
                      reader.onload = async (e) => {
                        try {
                          // Check if it's SRT file by name
                          if (file.name.endsWith(".srt")) {
                            const data = e.target.result;
                            const subtitles = data.split("\n\n");
                            function convertDurationToSeconds(duration) {
                              const parts = duration.split(":"); // Split the duration by colons
                              const secondsPart = parts[2].split(",")[0]; // Get the seconds part before the comma
                              const millisecondsPart = parts[2].split(",")[1]; // Get the milliseconds part after the comma

                              const hours = parseInt(parts[0], 10) || 0;
                              const minutes = parseInt(parts[1], 10) || 0;
                              const seconds = parseInt(secondsPart, 10) || 0;
                              const milliseconds =
                                parseInt(millisecondsPart, 10) || 0;

                              const totalSeconds =
                                hours * 3600 +
                                minutes * 60 +
                                seconds +
                                milliseconds / 1000;

                              return totalSeconds;
                            }
                            const sentences = subtitles.map((subtitle) => {
                              const [, timestamp, text] = subtitle.split("\n");
                              let [start, end] = timestamp.split(" --> ");
                              start = convertDurationToSeconds(start);
                              end = convertDurationToSeconds(end);
                              return {
                                start,
                                end,
                                transcript: text,
                                speaker: "0",
                                words: [{ word: text, start, end, conf: 1 }],
                              };
                            });

                            initEditor.current = false;
                            setSentences(sentences);
                            onSuccess("OK");
                          } else {
                            const sentences = JSON.parse(e.target.result);
                            initEditor.current = false;
                            setSentences(
                              sentences.map((sentence) => {
                                if (sentence.words) {
                                  return sentence;
                                } else {
                                  return {
                                    ...sentence,
                                    words: [
                                      {
                                        word: sentence.transcript,
                                        start: sentence.start,
                                        end: sentence.end,
                                        conf: 1,
                                      },
                                    ],
                                  };
                                }
                              })
                            );
                            onSuccess("OK");
                          }
                        } catch (e) {
                          onError(e);
                          messageApi.error({
                            content: "Bad file",
                          });
                        }
                      };
                    } catch (e) {
                      messageApi.error({
                        content: "Bad file",
                      });
                      onError("Bad file");
                    }
                  }}
                  type="file"
                >
                  <Button size="large" type="link" icon={<UploadOutlined />}>
                    Upload file
                  </Button>
                </Upload>
              )}
              {!dataToolRole && (
                <Upload
                  progress={{ style: { display: "none" } }}
                  maxCount={1}
                  accept="application/JSON, .srt"
                  showUploadList={false}
                  customRequest={({ file, onSuccess, onError }) => {
                    setUploadPreviewStatus("loading");
                    try {
                      const reader = new FileReader();
                      reader.readAsText(file);
                      reader.onload = async (e) => {
                        try {
                          // Check if it's SRT file by name
                          if (file.name.endsWith(".srt")) {
                            const data = e.target.result;
                            const subtitles = data.split("\n\n");
                            function convertDurationToSeconds(duration) {
                              const parts = duration.split(":"); // Split the duration by colons
                              const secondsPart = parts[2].split(",")[0]; // Get the seconds part before the comma
                              const millisecondsPart = parts[2].split(",")[1]; // Get the milliseconds part after the comma

                              const hours = parseInt(parts[0], 10) || 0;
                              const minutes = parseInt(parts[1], 10) || 0;
                              const seconds = parseInt(secondsPart, 10) || 0;
                              const milliseconds =
                                parseInt(millisecondsPart, 10) || 0;

                              const totalSeconds =
                                hours * 3600 +
                                minutes * 60 +
                                seconds +
                                milliseconds / 1000;

                              return totalSeconds;
                            }
                            const sentences = subtitles.map((subtitle) => {
                              const [, timestamp, text] = subtitle.split("\n");
                              let [start, end] = timestamp.split(" --> ");
                              start = convertDurationToSeconds(start);
                              end = convertDurationToSeconds(end);
                              return {
                                start,
                                end,
                                transcript: text,
                                speaker: "0",
                                words: [{ word: text, start, end, conf: 1 }],
                              };
                            });

                            initEditor.current = false;

                            setComparingSentences(sentences);
                            setUploadPreviewStatus("uploaded");
                            setShowCompare(true);
                            onSuccess("OK");
                          } else {
                            const sentences = JSON.parse(e.target.result);
                            initEditor.current = false;
                            setComparingSentences(
                              sentences.map((sentence) => {
                                if (sentence.words) {
                                  return sentence;
                                } else {
                                  return {
                                    ...sentence,
                                    words: [
                                      {
                                        word: sentence.transcript,
                                        start: sentence.start,
                                        end: sentence.end,
                                        conf: 1,
                                      },
                                    ],
                                  };
                                }
                              })
                            );
                            setUploadPreviewStatus("uploaded");
                            setShowCompare(true);
                            onSuccess("OK");
                          }
                        } catch (e) {
                          onError(e);
                          setComparingSentences(null);
                          messageApi.error({
                            content: "Bad file",
                          });
                        }
                      };
                    } catch (e) {
                      setComparingSentences(null);
                      messageApi.error({
                        content: "Bad file",
                      });
                      onError("Bad file");
                    }
                  }}
                  type="file"
                >
                  <Button
                    size="large"
                    type="link"
                    icon={<InteractionOutlined />}
                  >
                    Compare file
                  </Button>
                </Upload>
              )}
              {uploadPreviewStatus === "loading" && <Spin />}
              {uploadPreviewStatus === "uploaded" && (
                <Button
                  size="large"
                  type="link"
                  onClick={() => {
                    setComparingSentences(null);
                    setUploadPreviewStatus(null);
                  }}
                >
                  <CloseOutlined />
                </Button>
              )}
              {!dataToolRole && comparingSentences && (
                <Button
                  size="large"
                  type="link"
                  onClick={() => setShowCompare(!showCompare)}
                  icon={
                    showCompare ? <EyeInvisibleOutlined /> : <EyeOutlined />
                  }
                >
                  {showCompare ? "Hide compare" : "Show compare"}
                </Button>
              )}
              {comparingSentences &&
                dataToolRole &&
                isLastRoleSubmitted &&
                !readOnly &&
                dataToolRole !== "admin" && (
                  <Button
                    size="large"
                    type="link"
                    onClick={() => setShowCompare(!showCompare)}
                    icon={
                      showCompare ? <EyeInvisibleOutlined /> : <EyeOutlined />
                    }
                  >
                    {dataToolRole === "reviewer" &&
                      (showCompare
                        ? "Hide annotator data"
                        : "Show annotator data")}
                    {dataToolRole === "approver" &&
                      (showCompare
                        ? "Hide reviewer data"
                        : "Show reviewer data")}
                  </Button>
                )}
              {dataToolRole !== "admin" && !readOnly && (
                <Button
                  size="large"
                  htmlType="submit"
                  type="link"
                  icon={<RetweetOutlined />}
                  onClick={revertTranscriptionHandle}
                  loading={loadingRevert}
                >
                  {lang.revert}
                </Button>
              )}
              {!(
                dataToolRole === "annotator" ||
                dataToolRole === "reviewer" ||
                dataToolRole === "a"
              ) && (
                <Dropdown overlay={exportMenu}>
                  <Button
                    size="large"
                    htmlType="submit"
                    type="link"
                    icon={<DownloadOutlined />}
                  >
                    {lang.export}
                  </Button>
                </Dropdown>
              )}
              {dataToolRole !== "admin" && (
                <Button
                  size="large"
                  htmlType="submit"
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={saveTranscriptionHandle}
                  loading={saveLoading}
                  disabled={readOnly}
                >
                  {submitted
                    ? "Submitted"
                    : exceedDeadline
                    ? "Quá hạn"
                    : lang.btnSave}
                </Button>
              )}
              {(dataToolRole === "annotator" ||
                dataToolRole === "reviewer" ||
                dataToolRole === "approver") &&
                !readOnly && (
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    danger
                    icon={<CheckOutlined />}
                    onClick={handleSubmit}
                    disabled={!edited}
                  >
                    Submit
                  </Button>
                )}
            </Space>
          )}
        </Col>
      </Row>
    </Skeleton>
  );
};

export default MeetingDetailHeader;
