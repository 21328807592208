export const getTranscriptEditedDocId = (dataToolRole) => {
  let editedDocId = "";
  switch (dataToolRole) {
    case "annotator":
    case "reviewer":
    case "tool":
      editedDocId = dataToolRole;
      break;
    default:
      editedDocId = "user";
  }
  return editedDocId;
};
