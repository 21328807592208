import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  EditFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Row, Skeleton, Space } from "antd";
import Text from "antd/lib/typography/Text";
import TranscriptEditorV2 from "components/TranscriptEditor";
import useAuth from "hooks/useAuth";
import useDataToolRole from "hooks/useDataToolRole";
import _, { entries, groupBy, omit, orderBy, values } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { IS_FUNIX } from "utils/constants";
import { getTranscriptEditedDocId } from "utils/editor";
import { db } from "utils/firebase";
import { speakerToString } from "utils/utils";
import CreateSpeakerModal from "./CreateSpeakerModal";
import MediaPanel from "./MediaPanel";
import MeetingDetailHeader from "./MeetingDetailHeader";
import "./styles.less";
import EditSpeakerModal from "./EditSpeakerModal";
import useLocalStorage from "hooks/useLocalStorage";

const { REACT_APP_TENANT_ID } = process.env;

const MeetingDetail = () => {
  const { user } = useAuth();
  const { fileId } = useParams();

  const mediaRef = useRef(null);
  const playTo = useRef(null);

  const localSentences = useRef(null);
  const [sentences, setSentences] = useState(null);
  const dicts = useRef([]);
  const [comparingSentences, setComparingSentences] = useState(null);
  const [comparingSentences2, setComparingSentences2] = useState(null);
  const [comparingSentences3, setComparingSentences3] = useState(null);
  const [showCompare, setShowCompare] = useState(false);

  const [speakers, setSpeakers] = useState(null);
  const [autoSave, setAutoSave] = useLocalStorage("autoSave", true);

  const [pauseAtEnd, setPauseAtEnd] = useState(false);
  const pauseAtEndRef = useRef(pauseAtEnd);
  pauseAtEndRef.current = pauseAtEnd;

  const handleUpdateSpeakerLabelRef = useRef();

  const initEditor = useRef(false);
  const handleSave = useRef();

  const [fileMetadata, setFileMetadata] = useState(null);
  const [versionHistory, setVersionHistory] = useState([]);
  const [notes, setNotes] = useState("");
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [edited, setEdited] = useState(false);

  const dataToolRole = useDataToolRole();
  const fetched = useRef(false);

  const now = new Date();

  const exceedDeadline =
    (dataToolRole === "annotator" &&
      fileMetadata?.annotatorDeadline &&
      now > fileMetadata?.annotatorDeadline) ||
    (dataToolRole === "reviewer" &&
      fileMetadata?.reviewerDeadline &&
      now > fileMetadata?.reviewerDeadline) ||
    (dataToolRole === "approver" &&
      fileMetadata?.approverDeadline &&
      now > fileMetadata?.approverDeadline);

  // Fetch sentences and dicts
  useEffect(() => {
    if (!user || fetched.current) {
      return;
    }
    fetched.current = true;
    (async () => {
      try {
        // Get file metadata
        const fileRef = db.collection("files").doc(fileId);
        const fileMetadata = (await fileRef.get()).data();

        if (!fileMetadata) {
          setError("not_found");
          return;
        }

        if (dataToolRole === "annotator" && fileMetadata?.annotatorSubmitted) {
          setSubmitted(true);
        } else if (
          dataToolRole === "reviewer" &&
          fileMetadata?.reviewerSubmitted
        ) {
          setSubmitted(true);
        } else if (
          dataToolRole === "approver" &&
          fileMetadata?.approverSubmitted
        ) {
          setSubmitted(true);
        }

        if (dataToolRole === "annotator" && fileMetadata?.annotatorEdited) {
          setEdited(true);
        } else if (
          dataToolRole === "reviewer" &&
          fileMetadata?.reviewerEdited
        ) {
          setEdited(true);
        } else if (
          dataToolRole === "approver" &&
          fileMetadata?.approverEdited
        ) {
          setEdited(true);
        }

        setFileMetadata(fileMetadata);
        setNotes(fileMetadata.notes ?? "");

        // Get dict
        let query = db.collection("dicts");

        if (IS_FUNIX)
          query = query.where("tenantId", "==", REACT_APP_TENANT_ID);
        else query = query.where("userId", "==", user?.uid);

        const dictDocs = await query.orderBy("sortBy", "asc").get();
        dicts.current = dictDocs.docs.map((doc) => ({
          ...doc.data(),
          dictId: doc.id,
        }));

        // Get sentences
        if (dataToolRole === "admin") {
          const sentenceDoc = await fileRef.collection("sentences").get();
          let annotatorEditedDoc = await fileRef
            .collection("edited")
            .doc("annotator")
            .get();
          let reviewerEditedDoc = await fileRef
            .collection("edited")
            .doc("reviewer")
            .get();
          let approverEditedDoc = await fileRef
            .collection("edited")
            .doc("user")
            .get();
          localSentences.current = sentenceDoc.docs.map((doc) => doc.data());
          if (annotatorEditedDoc.exists) {
            setComparingSentences(annotatorEditedDoc.data().sentences);
          }
          if (reviewerEditedDoc.exists) {
            setComparingSentences2(reviewerEditedDoc.data().sentences);
          }
          if (approverEditedDoc.exists) {
            setComparingSentences3(approverEditedDoc.data().sentences);
          }
        } else {
          let sentenceDoc = await fileRef
            .collection("edited")
            .doc(getTranscriptEditedDocId(dataToolRole))
            .get();

          if (sentenceDoc.data()?.versionHistory) {
            setVersionHistory(sentenceDoc.data().versionHistory);
          }
          if (dataToolRole === "reviewer") {
            const annotatorEditedDoc = await fileRef
              .collection("edited")
              .doc("annotator")
              .get();
            if (!sentenceDoc.exists) {
              sentenceDoc = annotatorEditedDoc;
            }
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data()?.sentences;
            } else {
              sentenceDoc = await fileRef.collection("sentences").get();
              localSentences.current = sentenceDoc.docs.map((doc) =>
                doc.data()
              );
            }
            setComparingSentences(annotatorEditedDoc.data()?.sentences ?? []);
          } else if (dataToolRole === "approver") {
            const reviewerEditedDoc = await fileRef
              .collection("edited")
              .doc("reviewer")
              .get();
            if (!sentenceDoc.exists) {
              sentenceDoc = reviewerEditedDoc;
            }
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data()?.sentences;
            } else {
              sentenceDoc = await fileRef.collection("sentences").get();
              localSentences.current = sentenceDoc.docs.map((doc) =>
                doc.data()
              );
            }
            setComparingSentences(reviewerEditedDoc.data()?.sentences ?? []);
          } else {
            if (sentenceDoc.exists) {
              localSentences.current = sentenceDoc.data().sentences;
            } else {
              sentenceDoc = await fileRef.collection("sentences").get();
              localSentences.current = sentenceDoc.docs.map((doc) =>
                doc.data()
              );
            }
          }
        }

        const speakers = {};
        localSentences.current.forEach((sentence) => {
          const speaker = speakerToString(sentence.speaker);
          speakers[speaker] = {
            label: speaker,
            show: true,
            unused: false,
          };
        });
        setSpeakers(speakers);
        setSentences(_.cloneDeep(localSentences.current));
      } catch (e) {
        setError(e.message);
      }
    })();
  }, [user]);

  const debouncedSave = useCallback(
    _.debounce(() => handleSave.current(), 1000),
    []
  );

  if (error) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: 60,
          fontSize: 40,
          fontWeight: "bold",
        }}
        className="edit"
      >
        {error === "not_found" ? "Transcript not found!" : error}
      </div>
    );
  }

  const exportSentences =
    dataToolRole !== "admin"
      ? [{ sentences: localSentences.current }]
      : [
          { sentences: localSentences.current },
          comparingSentences
            ? { label: "annotator", sentences: comparingSentences }
            : null,
          comparingSentences2
            ? { label: "reviewer", sentences: comparingSentences2 }
            : null,
          comparingSentences3
            ? { label: "approver", sentences: comparingSentences3 }
            : null,
        ].filter((data) => data !== null);

  const isLastRoleSubmitted =
    (dataToolRole !== "reviewer" && dataToolRole !== "approver") ||
    (dataToolRole === "reviewer" && fileMetadata?.annotatorSubmitted) ||
    (dataToolRole === "approver" && fileMetadata?.reviewerSubmitted);

  return (
    <div className="edit">
      <MeetingDetailHeader
        exportSentences={exportSentences}
        autoSave={autoSave}
        setAutoSave={setAutoSave}
        handleSave={handleSave}
        submitted={submitted}
        initEditor={initEditor}
        isLastRoleSubmitted={isLastRoleSubmitted}
        setSubmitted={setSubmitted}
        exceedDeadline={exceedDeadline}
        setEdited={setEdited}
        edited={edited}
        fileMetadata={fileMetadata}
        localSentences={localSentences}
        showCompare={showCompare}
        setShowCompare={setShowCompare}
        versionHistory={versionHistory}
        setVersionHistory={setVersionHistory}
        notes={notes}
        comparingSentences={comparingSentences}
        sentences={sentences}
        setSentences={setSentences}
        setComparingSentences={setComparingSentences}
        pauseAtEnd={pauseAtEnd}
        setPauseAtEnd={setPauseAtEnd}
        setSpeakers={setSpeakers}
      />
      <Divider style={{ margin: 0 }} />
      <Row className="edit-row">
        <Col span={7} className="edit-rightcol">
          <Skeleton loading={!fileMetadata}>
            {fileMetadata && (
              <>
                <MediaPanel
                  duration={fileMetadata?.length}
                  ref={mediaRef}
                  playTo={playTo}
                  url={fileMetadata?.url}
                />
                {speakers && (
                  <div className="edit-video-note" direction="vertical">
                    <label htmlFor="notes-area">
                      <Text
                        style={{
                          textTransform: "uppercase",
                          fontWeight: 500,
                          marginLeft: "11px",
                          letterSpacing: "2px",
                        }}
                      >
                        Speakers
                      </Text>
                    </label>
                    <Divider style={{ margin: "4px" }} />
                    <div>
                      {entries(
                        groupBy(orderBy(speakers, "unused", "asc"), "unused")
                      ).map(([group, speakers]) => {
                        const unused = group === "true";
                        return (
                          <div style={{ padding: 12 }} key={group}>
                            <div style={{ marginBottom: 8 }}>
                              {!unused ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <CreateSpeakerModal setSpeakers={setSpeakers}>
                                    <Space style={{ cursor: "pointer" }}>
                                      <PlusOutlined />
                                      <Text strong>Create new Speaker</Text>
                                    </Space>
                                  </CreateSpeakerModal>
                                  <Checkbox
                                    checked={speakers.every(
                                      (speaker) => speaker.show
                                    )}
                                    indeterminate={
                                      speakers.some(
                                        (speaker) => speaker.show
                                      ) &&
                                      speakers.some((speaker) => !speaker.show)
                                    }
                                    onChange={(e) => {
                                      setSpeakers((speakers) => {
                                        const clonedSpeakers =
                                          _.cloneDeep(speakers);
                                        values(speakers).forEach(
                                          (speaker) =>
                                            (clonedSpeakers[
                                              speaker.label
                                            ].show = e.target.checked)
                                        );
                                        return clonedSpeakers;
                                      });
                                    }}
                                  >
                                    Show all
                                  </Checkbox>
                                </div>
                              ) : (
                                <div>
                                  <Text strong>Unused Speakers</Text>
                                </div>
                              )}
                            </div>
                            {orderBy(speakers, "label", "asc").map(
                              (speaker) => (
                                <div
                                  key={speaker.label}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingLeft: "22px",
                                  }}
                                >
                                  <Text style={{ fontSize: "15px" }}>
                                    {speaker.label}
                                  </Text>
                                  <Space>
                                    <div>
                                      {!unused && (
                                        <Checkbox
                                          checked={speaker.show}
                                          onChange={(e) => {
                                            setSpeakers((speakers) => {
                                              const clonedSpeakers =
                                                _.cloneDeep(speakers);
                                              clonedSpeakers[
                                                speaker.label
                                              ].show = e.target.checked;
                                              return clonedSpeakers;
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                    <EditSpeakerModal
                                      handleUpdateSpeakerLabelRef={
                                        handleUpdateSpeakerLabelRef
                                      }
                                      setSpeakers={setSpeakers}
                                      speaker={speaker.label}
                                    >
                                      <Button
                                        type="text"
                                        icon={<EditFilled />}
                                      />
                                    </EditSpeakerModal>
                                    {unused && (
                                      <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                          setSpeakers((speakers) =>
                                            omit(speakers, speaker.label)
                                          );
                                        }}
                                      />
                                    )}
                                  </Space>
                                </div>
                              )
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </Skeleton>
        </Col>
        <Col span={17} className="edit-leftcol">
          <Skeleton loading={!sentences} paragraph={{ rows: 15 }}>
            <div style={{ display: "flex" }}>
              {sentences && sentences.length > 0 ? (
                <div
                  style={
                    (comparingSentences ||
                      comparingSentences2 ||
                      comparingSentences3) &&
                    (showCompare || dataToolRole === "admin")
                      ? {
                          maxHeight: "calc(100vh - 125px)",
                          overflowY: "scroll",
                          flex: 1,
                        }
                      : {
                          flex: 1,
                        }
                  }
                >
                  <TranscriptEditorV2
                    lang={fileMetadata?.lang}
                    handleUpdateSpeakerLabelRef={handleUpdateSpeakerLabelRef}
                    speakers={speakers}
                    setSpeakers={setSpeakers}
                    pauseAtEnd={pauseAtEndRef}
                    readOnly={
                      dataToolRole === "admin" ||
                      submitted ||
                      !isLastRoleSubmitted ||
                      exceedDeadline
                    }
                    playTo={playTo}
                    sentences={sentences}
                    dicts={dicts.current}
                    onChange={(value) => {
                      if (!_.isEqual(localSentences.current, value)) {
                        if (initEditor.current && autoSave) debouncedSave();
                        initEditor.current = true;
                      }
                      localSentences.current = _.cloneDeep(value);
                    }}
                    videoRef={mediaRef}
                  />
                </div>
              ) : (
                <div>Not found</div>
              )}
              {comparingSentences &&
                !isLastRoleSubmitted &&
                (dataToolRole === "reviewer" ||
                  dataToolRole === "approver") && (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxHeight: "calc(100vh - 125px)",
                    }}
                  >
                    <Text strong style={{ fontSize: 32 }}>
                      The{" "}
                      {dataToolRole === "reviewer" ? "annotator" : "reviewer"}{" "}
                      has not submitted yet.
                    </Text>
                  </div>
                )}
              {comparingSentences &&
                isLastRoleSubmitted &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      lang={fileMetadata?.lang}
                      speakers={speakers}
                      pauseAtEnd={pauseAtEndRef}
                      readOnly
                      playTo={playTo}
                      sentences={comparingSentences}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
              {comparingSentences2 &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      lang={fileMetadata?.lang}
                      speakers={speakers}
                      readOnly
                      pauseAtEnd={pauseAtEndRef}
                      playTo={playTo}
                      sentences={comparingSentences2}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
              {comparingSentences3 &&
                (showCompare || dataToolRole === "admin") && (
                  <div
                    style={{
                      flex: 1,
                      maxHeight: "calc(100vh - 125px)",
                      overflowY: "scroll",
                    }}
                  >
                    <TranscriptEditorV2
                      lang={fileMetadata?.lang}
                      speakers={speakers}
                      pauseAtEnd={pauseAtEndRef}
                      readOnly
                      playTo={playTo}
                      sentences={comparingSentences3}
                      dicts={dicts.current}
                      onChange={() => {}}
                      videoRef={mediaRef}
                    />
                  </div>
                )}
            </div>
          </Skeleton>
          <div className="navigate-container">
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const ele = document.getElementsByClassName("edit-leftcol")[0];
                ele.scrollTo({
                  top: ele.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              <ArrowDownOutlined />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default React.memo(MeetingDetail);
