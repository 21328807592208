import React, { useState, useEffect } from "react";

import { Layout, Card, Upload, Row, Col, DatePicker, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import moment from "moment-timezone";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import _extends from "@babel/runtime/helpers/extends";
import CalendarLocale from "rc-picker/es/locale/vi_VN";
import TimePickerLocale from "antd/es/time-picker/locale/vi_VN";

import usLocale from "antd/lib/date-picker/locale/en_US";

import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyBGvSvdeMxda3Z4tyLHpWgDtlWQhirUA4c",
  authDomain: "namisense-funix.firebaseapp.com",
  projectId: "namisense-funix",
  storageBucket: "namisense-funix.appspot.com",
  messagingSenderId: "126180177380",
  appId: "1:126180177380:web:8c159dd587ae6467b243f1",
};

const app = firebase.initializeApp(config, "FUNiX");

const storage = app.storage();
const auth = app.auth();

const { Dragger } = Upload;

const locale = {
  lang: _extends(
    {
      placeholder: "Chọn thời điểm",
      rangePlaceholder: ["Từ ngày", "Đến ngày"],
    },
    CalendarLocale
  ),
  timePickerLocale: _extends({}, TimePickerLocale),
};

const FunixUpload = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguages(t);

  const [date, setDate] = useState(moment());

  const onSelectFile = (e) => {
    if (e.file.name) {
      const file = e.file;

      if (!file) {
        alert("Please choose a file first!");
      }

      const storageRef = storage.ref(`${date.format("DDMMYYYY")}`);
      const fileRef = storageRef.child(`${file.name}`);

      const uploadTask = fileRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          if (e.onProgress) e.onProgress({ percent: percent });
        },
        (err) => console.log(err),
        () => {
          if (e.onSuccess) e.onSuccess();
        }
      );
    }
  };

  const props = {
    name: "file",
    multiple: true,
    customRequest: onSelectFile,
    showUploadList: true,
  };

  useEffect(() => {
    auth
      .signInWithEmailAndPassword("funix@yopmail.com", "123.vietnam")
      .then(async () => {
        console.log("Funix login success");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(`${errorCode} - ${errorMessage}`);
      });
  }, []);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        <Card style={{ marginBottom: 18 }} bordered={false}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify="space-between"
          >
            <Col className="gutter-row" span={12}>
              <h3>{language.btnUpload}</h3>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ marginBottom: 18 }}
            justify="space-between"
          >
            <Col className="gutter-row" span={12}>
              <DatePicker
                value={date}
                onChange={(date, dateString) => {
                  if (date.isAfter(moment(), "day")) {
                    setDate(moment());
                    message.error(language.uploadDateFuture);
                  } else {
                    setDate(date);
                  }
                }}
                format="DD-MM-YYYY"
                allowClear={false}
                locale={i18n.language === "en" ? usLocale : locale}
              />
            </Col>
          </Row>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined style={{ fontSize: 36, color: "inherit" }} />
            </p>
            <p className="ant-upload-text">{language.btnUpload}</p>
          </Dragger>
        </Card>
      </Layout>
    </Layout>
  );
};

export default FunixUpload;
