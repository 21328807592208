import React, { useState } from "react";

import { Form, Input, Button, Row, Col, message, Checkbox } from "antd";

import { auth } from "utils/firebase";

import "./styles.less";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";
import Axios from "axios";

const { REACT_APP_TENANT_ID } = process.env;

const UserUpdateForm = ({ user, onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const lang = getLanguages(t);

  return (
    <Form
      form={form}
      layout="vertical"
      name="userUpdateForm"
      onFinish={async (values) => {
        setLoading(true);

        let userUpdate = {
          name: values?.name,
          email: values?.email,
          phone: values?.phone || "",
          address: values?.address || "",
          isActive: values?.isActive,
          tenantId: REACT_APP_TENANT_ID,
          password: values?.password,
        };

        const apiUrl = process.env.REACT_APP_API + "/admin/users/" + user?.id;
        try {
          await Axios.patch(apiUrl, userUpdate, {
            headers: {
              Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
            },
          });
          setLoading(false);
          message.success("User has been update successfully");
          onCallback();
        } catch (err) {
          message.error(err?.response?.data?.message ?? "Server Error");
          setLoading(false);
        }
      }}
      initialValues={user}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
              ]}
              name="name"
              label="Name"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item name="phone" label={"Phone"}>
              <Input placeholder={"0987675234"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label={"Address"}>
              <Input placeholder={"CC Hoàng Sa, Đường D1, Phường An Thuận Động, Q7, HCM"} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="password"
              label={"Password"}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value?.length === 0) return Promise.resolve();
                    if (value.length < 8) return Promise.reject(lang.passwordErrorMinimum);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Button type="clear" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Row>
      </div>
    </Form>
  );
};

export default UserUpdateForm;
