import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Layout, Tabs } from "antd";

import "./styles.less";

const { TabPane } = Tabs;

const EmployerLayout = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState(1);

  useEffect(() => {
    if (window.location.pathname === "/user/streaming") setKey("streaming");
    else setKey("record");
  }, [window.location.pathname]);

  return (
    <div className="employer-layout">
      <Layout>
        <Tabs
          activeKey={key}
          type="card"
          size="medium"
          onChange={(e) => {
            if (e === "streaming") navigate(`/user/streaming`);
            else navigate(`/user/record`);
          }}
          tabBarStyle={{ margin: "25px 24px 0px 24px" }}
        >
          <TabPane tab="Full Context" key="record"></TabPane>
          <TabPane tab={"Streaming"} key="streaming"></TabPane>
        </Tabs>
        <Outlet />
      </Layout>
    </div>
  );
};

export default EmployerLayout;
