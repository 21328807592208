import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select, message } from "antd";

import { db } from "utils/firebase";
import firebase from "firebase";

import "./styles.less";
import useAuth from "hooks/useAuth";
import axios from "axios";

const { REACT_APP_TENANT_ID } = process.env;

const MeetingTaskCreateForm = ({ onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  return (
    <Form
      form={form}
      layout="vertical"
      name="meetingTaskUpdateForm"
      onFinish={async (values) => {
        setLoading(true);
        if (user) {
          let meetingTaskAdd = {
            createdAt: firebase.firestore.Timestamp.now(),
            roomNumber: values?.meetingNumber,
            language: values?.language ?? "jp",
            tenantId: REACT_APP_TENANT_ID,
            userId: user.uid,
            status: "INIT",
          };

          try {
            const doc = await db.collection("meetingTasks").add(meetingTaskAdd);
            await axios.post(
              "https://muezhu7noi.execute-api.ap-southeast-1.amazonaws.com/zoom-bot",
              {
                meetingNumber: values.meetingNumber,
                meetingPassword: values.meetingPassword,
                taskId: doc.id,
                tenantId: REACT_APP_TENANT_ID,
                userId: user.uid,
                lang: values?.language ?? "jp",
              }
            );
          } catch (e) {
            message.error("error");
            console.log(e);
          }
        }
        setLoading(false);
        onCancel();
      }}
      initialValues={{}}
    >
      <div className="meeting-task-form">
        <Row gutter={24} type="flex">
          <Col span={12}>
            <Form.Item
              name="meetingNumber"
              label="Meeting number"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="meetingPassword"
              label="Meeting password"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Language" name="language">
          <Select defaultValue={"jp"} style={{ width: "100%" }}>
            <Select.Option value={"vn"}>Vietnamese</Select.Option>
            <Select.Option value={"jp"}>Japanese</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Create
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default MeetingTaskCreateForm;
